.video-section-container {
  margin-top: 3rem;
  margin-left: 0rem;
  background-color: white;
  height: 80vh;
  // padding: 3rem;
  border-radius: 30px;

  .video-container {
    width: 100%;
    height: 100%;
    border-radius: 30px;

    video {
      object-fit: cover;
      border-radius: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    height: 30vh;

    .video-container {
      margin-left: -1rem;
      width: calc(100% + 2rem);
      height: 100%;
    }
  }
}
