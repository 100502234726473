@import "../../DesignSystem/library";

.cater-to-container {
  position: relative;
  background-color: #106666;

  border-radius: 30px;
  margin-top: -1.5rem;
  padding: 1.5rem;
  font-family: $font-primary;
  height: auto;
  @media (max-width: 768px) {
    border-radius: 16px;
  }
  @media (max-width: 468px) {
     margin-bottom: 1.5rem;
  }

  .ctc-title {
    font-size: 28px;
    margin-bottom: 1rem;
    color: #ffffff;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 26px;
      font-weight: 300;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 0.5rem;
    }

    @media (max-width: 425px) {
      font-size: 26px;
      font-weight: 300;
    }
  }

  .ctc-separator {
    height: 1px;
    width: 100%;
    background-color: $gray-500;
    z-index: 10;
    margin-bottom: -5rem;
    @media (max-width: 480px) {
      margin-bottom: -1rem;
    }
  }

  .ctc-desc {
    display: flex;
    justify-content: end;
    width: 100%;
    float: right;
    margin: 4rem 0rem;
    line-height: 24px;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0.5px;

    p {
      font-weight: 300;
      width: 100%;
      font-size: 16px;
    }
  }

  .ctc-cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    @media (max-width: 768px) {
      margin-top: 2rem;
      gap: 0.5rem;
    }
    @media (max-width: 425px) {
      margin-top: 2rem;
      gap: 0.5rem;
      position: relative;
    }
  }

  .ctc-card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 1.2rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 768px) {
      border-radius: 10px;
    }
    @media (max-width: 480px) {
      border-radius: 6px;
    }
  }

  .ctc-card-title {
    font-size: 18px;
    font-weight: 600;
    color: $turquoise-100;

    @media (max-width: 480px) {
      font-size: 12px !important;
      font-weight: 500;
      &.active {
        font-weight: 600;
      }
    }
  }

  .each-card {
    display: flex;
    .white-space {
      flex: 1;
    }
    .ctc-card-desc {
      flex: 2;
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 16px;
      line-height: 18px;
      font-weight: 300;
      position: relative;
      bottom: -0.4rem;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 14px;
      }
      @media (max-width: 480px) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .description-content {
    width: 50%;
    padding-left: 1rem;
  }

  .empty-space {
    width: 50%;
  }

  @media (max-width: 425px) {
    .ctc-card-title {
      font-size: 14px;
    }

    .ctc-card-desc {
      flex-direction: column;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
    }

    .description-content,
    .empty-space {
      width: 100%;
      padding-left: 0;
    }
  }

  .top-wave-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .bottom-wave-container {
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    z-index: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }
}
