@import "../../../DesignSystem/library";

.clem-vision {
  background-color: white;
  border-radius: 30px;
  padding: 3rem;
  font-family: $font-primary;
  display: flex;
  flex-direction: column;

  @media (max-width: 760px) {
    padding: 1.5rem;
    border-radius: 20px;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    border-radius: 16px;
    margin-bottom: 1.5rem;
  }

  .vision-title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: $turquoise-100;

    @media (max-width: 1200px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (max-width: 768px) {
      font-size: 26px;
      font-weight: 300;
      line-height: 36px;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
      margin: 0.3rem 0;
    }
  }

  .vision-desc {
    font-family: $font-primary;
    color: #111111;
    margin: 3rem 0rem;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 0.07rem;
    font-weight: 500;
    width: 40%;
    margin-left: auto;

    @media (max-width: 1200px) {
      font-size: 16px;
      width: 60%;
      letter-spacing: 0.05rem;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      margin: 1rem 0rem;
      width: 100%;
      font-size: 12px;
      letter-spacing: 0.05rem;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      margin: 0.5rem 0rem;
      font-size: 10px;
      letter-spacing: 0.03rem;
      line-height: 16px;
    }
  }

  .clem-submodules {
    display: flex;
    flex-direction: row;
    gap: 40px;
    // justify-content: space-between;

    @media (max-width: 768px) {
      gap: 20px;
    }

    .clem-submodule {
      position: relative;

      .header-img {
        width: 100%;
        object-fit: cover;
        border-radius: 30px;

        @media (max-width: 768px) {
          height: 150px;
          width: 100%;
          border-radius: 15px;
        }

        @media (max-width: 480px) {
          height: 100px;
          width: 100%;
          border-radius: 10px;
        }
      }

      .title {
        position: absolute;
        top: 70%;
        left: 10%;
        color: #fff;
        font-size: 48px;
        font-weight: 300;

        @media (max-width: 1200px) {
          font-size: 26px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }

        @media (max-width: 480px) {
          font-size: 10px;
        }
      }

      .arrow {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        path {
          fill: #fff;
        }

        @media (max-width: 768px) {
          top: 15px;
          right: 15px;
          width: 13px;
          height: 13px;
        }

        @media (max-width: 480px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
