@import "../../../../DesignSystem/library";

.title-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #fff;
  cursor: pointer;
  padding: 1.5rem 3rem;
  border-radius: 30px;
  margin-top: 3rem;
  background-color: #106666;

  @media screen and (max-width: 600px) {
    border-radius: 10px;
    margin-top: 1.5rem;
    padding: 1rem 1.5rem;
  }

  @media screen and (min-width: 0px) and (max-width: 600px) {
    gap: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    gap: 35px;
  }
}

.title {
  font-size: 48px;
  font-weight: 500;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    font-size: 14px;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 32px;
  }
}

.toggle,
.redirect {
  font-size: 40px;
  font-weight: 500;
  align-self: flex-end;
  cursor: pointer;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    font-size: 20px;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 36px;
  }
}

.redirect {
  svg {
    path {
      fill: white;
    }
  }
}

.open-container {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 600px) {
    border-radius: 10px;
    margin-top: 1.5rem;
    padding: 1rem 1.5rem;
  }

  .strategy-img-container {
    width: 50%;
    margin: 1.5rem auto 4rem auto;

    .strategy-img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    @media screen and (min-width: 0px) and (max-width: 600px) {
      width: 90%;
      margin-bottom: 2rem;
    }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      width: 75%;
      margin-bottom: 3rem;
    }
  }

  .outcomes-container {
    .outcomes-title {
      color: #111111;
      font-size: 24px;
      line-height: 30px;
      font-weight: 550;
      letter-spacing: 0.5px;
      margin-bottom: 30px;

      span {
        position: relative;
        left: 2px;
        bottom: 2px;
      }

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.1px;
        margin-bottom: 10px;
      }

      @media screen and (min-width: 600px) and (max-width: 1200px) {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.35px;
        margin-bottom: 20px;
      }
    }

    .outcomes-list {
      margin-left: 15px;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        letter-spacing: 0.1px;
      }

      @media screen and (min-width: 600px) and (max-width: 1200px) {
        letter-spacing: 0.35px;
      }

      li {
        font-size: 20px;
        margin-top: 16px;
        line-height: 26px;
        color: $turquoise-100;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 12px;
          margin-top: 12px;
          line-height: 16px;
        }

        @media screen and (min-width: 600px) and (max-width: 1200px) {
          font-size: 16px;
          margin-top: 14px;
          line-height: 20px;
        }
      }
    }

    .outcomes-subtext {
      font-weight: 550;
    }

    .outcomes-desc {
      font-weight: 300;
      color: #111111;
    }
  }
}

.open-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a7a7a7;
  padding-bottom: 1rem;
  cursor: pointer;

  .title {
    font-size: 48px;
    font-weight: 500;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      font-size: 14px;
    }
    @media screen and (min-width: 600px) and (max-width: 1200px) {
      font-size: 32px;
    }
  }

  .minus {
    height: 4px;
    width: 18px;
    background-color: #111111;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      height: 2px;
      width: 10px;
    }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      height: 2.5px;
      width: 16px;
    }
  }
}

.info {
  width: 85%;
  text-align: center;
  margin: 60px auto 60px auto;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin: 25px auto 25px auto;
  }

  @media screen and (min-width: 600px) and (max-width: 1200px) {
    width: 90%;
    text-align: center;
    margin: 35px auto 35px auto;
  }

  .sub-title {
    font-size: 24px;
    font-weight: 600;
    color: $turquoise-100;
    margin-bottom: 20px;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      font-size: 14px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .text {
    color: #111111;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
    }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.35px;
    }
  }

  .text1 {
    margin-bottom: 70px;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      margin-bottom: 30px;
    }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      margin-bottom: 50px;
    }
  }
}
