@import "../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem;
  padding-top: 1.5rem;
  font-family: $font-primary;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 0.8rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }
}

.header {
  position: relative;

  .header-img {
    width: 100%;
    height: 609px;
    object-fit: cover;
    border-radius: 30px;
    filter: brightness(0.3);

    @media (max-width: 768px) {
      border-radius: 20px;
      height: 60vh;
    }

    @media (max-width: 480px) {
      border-radius: 20px;
      height: 50vh;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 128px;
    font-weight: 300;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 64px;
    }

    @media (max-width: 480px) {
      font-size: 42px;
    }
  }
}

.contact-details {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;

  @media (max-width: 768px) {
    border-radius: 10px;
    background-color: #fff;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    border-radius: 10px;
    background-color: #fff;
    padding: 1.5rem;
  }

  .title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: $turquoise-100;

    @media (max-width: 768px) {
      font-size: 28px;
      font-weight: 500;
      line-height: unset;
    }

    @media (max-width: 480px) {
      font-size: 12px;
      font-weight: 500;
      line-height: unset;
    }
  }

  .inner-container {
    width: 48%;
    margin-left: auto;

    @media (max-width: 768px) {
      width: 60%;
      margin-left: auto;
    }

    @media (max-width: 480px) {
      width: 60%;
      margin-left: auto;
    }

    .row {
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
      height: 1px;
      margin: 0.6rem 0;
    }

    @media (max-width: 480px) {
      height: 0.5px;
      margin: 0.6rem 0;
    }
  }

  .text,
  .btn {
    color: #000000;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }

  .text {
    @media (max-width: 768px) {
      margin: 1rem 0;
      margin-left: auto;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.2px;
    }

    @media (max-width: 480px) {
      margin: 1rem 0;
      margin-left: auto;
      font-size: 10px;
      line-height: 11px;
    }
  }

  .text1 {
    @media (max-width: 768px) {
      margin: unset;
    }

    @media (max-width: 480px) {
      font-size: 8px;
      margin: unset;
      line-height: 10px;
    }
  }

  .link {
    cursor: pointer;
    color: $turquoise-100;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 2rem;

    @media (max-width: 768px) {
      gap: 1.2rem;
      padding-top: 1rem;
      width: 100%;
    }

    @media (max-width: 480px) {
      gap: 1rem;
      padding-top: 0.8rem;
      width: 100%;
    }

    .field-holder {
      position: relative;
      margin-bottom: 1.25rem;
      width: 100%;

      input,
      textarea {
        width: 100%;
        font-family: $font-primary;
      }

      input {
        border: 0;
        border-bottom: 1px solid #cdcdcd;
        outline: none;
        font-size: 16px;
      }

      textarea {
        outline: none;
        resize: none;
        border: 0;
        max-height: 150px;
        border-bottom: 1px solid #a7a7a7;
        font-size: 16px;
      }

      .label {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        cursor: text;
        transition: 0.3s ease-in-out;
      }

      input,
      label {
        height: 40px;
      }

      textarea {
        height: 30px;
        min-height: 30px;
      }

      textarea + label {
        height: 22px;
      }

      label {
        color: #111111;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
      }

      input:focus + label {
        font-size: 14px;
        top: -25px;
        pointer-events: none;
      }

      textarea:focus + label {
        font-size: 14px;
        top: -25px;
        pointer-events: none;
      }

      input:not(:placeholder-shown) + label,
      textarea:not(:placeholder-shown) + label {
        font-size: 14px;
        top: -25px;
        pointer-events: none;
      }

      input::placeholder,
      textarea::placeholder {
        color: transparent;
      }

      input::-webkit-input-placeholder {
        color: transparent;
      }
      input:-moz-placeholder {
        color: transparent;
      }
      input::-moz-placeholder {
        color: transparent;
      }
      input:-ms-input-placeholder {
        color: transparent;
      }

      textarea::-webkit-input-placeholder {
        color: transparent;
      }
      textarea:-moz-placeholder {
        color: transparent;
      }
      textarea::-moz-placeholder {
        color: transparent;
      }
      textarea:-ms-input-placeholder {
        color: transparent;
      }

      @media (max-width: 768px) {
        margin-bottom: unset;

        input,
        textarea {
          width: 100%;
          font-family: $font-primary;
          font-size: 14px;
        }

        input {
          border: 0;
          border-bottom: 1px solid #cdcdcd;
          outline: none;
        }

        textarea {
          outline: none;
          resize: none;
          border: 0;
          max-height: 80px;
          border-bottom: 1px solid #a7a7a7;
        }

        .label {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          cursor: text;
          transition: 0.3s ease-in-out;
        }

        input,
        label {
          height: 28px;
          font-size: 12px;
        }

        textarea {
          height: 19px;
          min-height: 19px;
        }

        textarea + label {
          height: 13px;
        }

        label {
          font-size: 12px;
          line-height: 14px;
        }

        input:focus + label,
        textarea:focus + label {
          font-size: 10px;
          top: -15px;
          pointer-events: none;
        }

        input:not(:placeholder-shown) + label,
        textarea:not(:placeholder-shown) + label {
          font-size: 10px;
          top: -15px;
          pointer-events: none;
        }
      }

      @media (max-width: 480px) {
        margin-bottom: unset;

        input,
        textarea {
          width: 100%;
          font-family: $font-primary;
          font-size: 12px;
        }

        input {
          border: 0;
          border-bottom: 0.5px solid #cdcdcd;
          outline: none;
        }

        textarea {
          outline: none;
          resize: none;
          border: 0;
          max-height: 80px;
          border-bottom: 0.5px solid #a7a7a7;
        }

        .label {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          cursor: text;
          transition: 0.3s ease-in-out;
        }

        input,
        label {
          height: 26px;
          font-size: 10px;
        }

        textarea {
          height: 17px;
          min-height: 17px;
        }

        textarea + label {
          height: 11px;
        }

        label {
          font-size: 10px;
          line-height: 12px;
        }

        input:focus + label,
        textarea:focus + label {
          font-size: 8px;
          top: -13px;
          pointer-events: none;
        }

        input:not(:placeholder-shown) + label,
        textarea:not(:placeholder-shown) + label {
          font-size: 8px;
          top: -13px;
          pointer-events: none;
        }
      }
    }

    .textarea-container {
      @media (max-width: 768px) {
        margin-top: 9px;
      }

      @media (max-width: 480px) {
        margin-top: 9px;
      }
    }

    .btn-container {
      display: flex;
      gap: 4rem;

      @media (max-width: 768px) {
        display: flex;
        gap: 1.2rem;
        justify-content: space-between;
      }

      @media (max-width: 480px) {
        display: flex;
        gap: 0.8rem;
        justify-content: space-between;
      }

      .checkbox-container {
        display: flex;
        align-items: flex-start;
        gap: 0.6rem;

        input.checkbox {
          width: 24px;
          height: 18px;
          cursor: pointer;
        }

        input[type="checkbox"] {
          accent-color: $turquoise-100;
        }

        @media (max-width: 768px) {
          display: flex;
          align-items: flex-start;
          gap: 0.4rem;

          input.checkbox {
            width: 15px;
            height: 12px;
          }
        }

        @media (max-width: 480px) {
          display: flex;
          align-items: flex-start;
          gap: 0.4rem;

          input.checkbox {
            width: 12px;
            height: 9px;
          }
        }
      }

      .btn {
        background-color: #111111;
        padding: 1rem 2rem;
        color: #fff;
        height: fit-content;

        @media (max-width: 768px) {
          font-size: 12px;
          padding: 0.7rem 1.6rem;
          border-radius: 3px;
          margin-left: 20px;
        }

        @media (max-width: 480px) {
          font-size: 8px;
          padding: 0.3rem 1.4rem;
          border-radius: 3px;
          margin-left: 20px;
        }
      }

      .btn-submitted {
        background-color: $turquoise-100;
      }

      .btn-disabled {
        color: #000;
        background-color: #e5e5e5;
        cursor: not-allowed;
        font-weight: 500;
      }
    }

    .error {
      color: #b12e2e;
      font-size: 12px;
      margin-top: 0.4rem;

      @media (max-width: 768px) {
        font-size: 9px;
        margin-top: 0.35rem;
      }

      @media (max-width: 480px) {
        font-size: 7px;
        margin-top: 0.3rem;
      }
    }
  }
}
