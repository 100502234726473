@import "../../../DesignSystem/library";

.strategy-container {
  background-color: white;
  border-radius: 30px;
  padding: 3rem;
  font-family: $font-primary;
  margin-top: 1.5rem;
  @media (max-width: 468px) {
    margin-top: 0rem;
    border-radius: 16px;
  }

  .strategy-title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: $turquoise-100;

    @media (max-width: 1200px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (max-width: 768px) {
      font-size: 26px;
      font-weight: 300;
      line-height: 36px;
      font-weight: 300;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;
  }

  .se-strategy-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dots-line-container {
    position: relative;
    margin-top: 3rem;

    .line {
      position: absolute;
      height: 1.5px;
      width: calc(100% + 6rem);
      background-color: $gray-800;
      z-index: 0;
      top: 12px;
      left: -3rem;

      @media (max-width: 768px) {
        width: calc(100% + 3rem);
        top: 8px;
      }
    }

    .dots-container {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      padding: 0 1rem;
      transition: all 0.5s linear;

      .dot {
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: white;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        font-weight: 600;

        @media (max-width: 768px) {
          height: 20px;
          width: 20px;
          font-size: 14px;
        }
      }

      .dot-expanded {
        height: 52px;
        width: 52px;
        margin-top: -12px;
        font-size: 24px;

        @media (max-width: 768px) {
          height: 40px;
          width: 40px;
          font-size: 18px;
        }
      }
    }

    .arrow-card-container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      height: auto;

      .arrow-left-container {
        display: flex;
        align-items: center;
        flex: 1;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $gray-300;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          cursor: pointer;

          @media (max-width: 768px) {
            height: 40px;
            width: 40px;
          }
        }
      }

      .card-container {
        flex: 6;
        display: flex;
        justify-content: center;

        .card-body {
          border-radius: 15px;
          height: auto;
          width: 100%;
          max-width: 302px;
          box-shadow: 0px 4px 10px 0px #47474740;
          background-color: $blue-200;
          padding: 1.5rem;

          .card-title {
            font-weight: 600;
            font-size: 24px;
            color: $black-300;

            @media (max-width: 768px) {
              font-size: 18px;
            }
          }

          .card-desc {
            font-size: 16px;
            margin-top: 2rem;

            @media (max-width: 768px) {
              font-size: 14px;
              margin-top: 1rem;
            }
          }
        }
      }

      .arrow-right-container {
        display: flex;
        align-items: center;
        justify-content: end;
        flex: 1;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $gray-300;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          cursor: pointer;

          @media (max-width: 768px) {
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
}
