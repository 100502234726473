@import "../../DesignSystem//library";

.strategy-container {
  background-color: white;
  border-radius: 12px;
  padding-top: 1rem;
  font-family: $font-primary;

  .strategy-title {
    padding-left: 1.5rem;
    font-size: 16px !important;
    margin-bottom: -19px !important;
    font-weight: 500;
    line-height: 56px;
    color: $turquoise-100;

    @media (max-width: 1200px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (max-width: 768px) {
      font-size: 26px!important;
      font-weight: 300;
      line-height: 36px;
    }
  }

  .separator {
    width: 80%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;
    position: relative;
    left: 1.5rem;
  }

  .se-strategy-img-container {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -2rem;
    position: relative;
    bottom: 1rem;

    img {
      width: 100%;
      height: auto;
      max-width: 500px;
    }
  }
}

.arrow-card-container {
  padding: unset !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  height: auto;
  position: relative;
  bottom: 0.9rem;

  .arrow-left-container,
  .arrow-right-container {
    display: flex;
    align-items: center;
    flex: 1;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-300;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      cursor: pointer;

      @media (max-width: 768px) {
        height: 35px;
        width: 35px;
      }
    }
  }

  .cards-scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 0.8rem;
    flex: 6;
    padding: 0 1rem;

    /* Hide scrollbar for WebKit browsers */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .card-container {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      width: 180px;
      @media (max-width: 480px) {
        width: 180px;
      }

      .card-body {
        border-radius: 8px;
        height: auto;
        width: 100%;
        background-color: $blue-200;
        padding: 1rem;
        @media (max-width: 480px) {
          box-shadow: 0px 5px 5px 0px rgba(71, 71, 71, 0.25);
          margin-bottom: 1.5rem;
        }

        .card-title {
          font-weight: 600;
          font-size: 18px;
          color: $black-300;

          @media (max-width: 768px) {
            font-size: 16px;
          }
          @media (max-width: 480px) {
            font-size: 12px;
          }
        }

        .card-desc {
          font-size: 14px;
          margin-top: 1rem;

          @media (max-width: 768px) {
            font-size: 12px;
            margin-top: 0.5rem;
          }
          @media (max-width: 480px) {
            font-size: 10px;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
