@import "../../../DesignSystem/library";

.container {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;

  @media (max-width: 768px) {
    border-radius: 20px;
    background-color: #fff;
    padding: 2rem;
  }

  @media (max-width: 480px) {
    border-radius: 20px;
    background-color: #fff;
    padding: 1.5rem;
  }

  .title {
    font-size: 48px;
    font-weight: 500;
    border-bottom: 1px solid #a7a7a7;
    padding-bottom: 1.5rem;
    color: $turquoise-100;

    @media (max-width: 768px) {
      font-size: 28px;
      padding-bottom: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 0.5rem;
      border-bottom: 0.5px solid #a7a7a7;
    }
  }

  .text {
    margin: 4.5rem 0 3.5rem 0;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    max-width: 480px;
    margin-left: auto;
    font-weight: 500;

    @media (max-width: 768px) {
      margin: 2rem 0 1.2rem 0;
      font-size: 12px;
      line-height: 16px;
      margin-left: auto;
      max-width: unset;
      width: 50%;
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  .image-slider {
    display: flex;
    gap: 1rem;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-bottom: 3.8rem;

    @media (max-width: 768px) {
      gap: 0.5rem;
      margin-top: 1rem;
      margin-bottom: 1.2rem;
    }

    @media (max-width: 480px) {
      gap: 0.5rem;
      margin: 1rem 0;
    }

    .image {
      min-width: calc((100% - 2rem) / 3);
      max-width: calc((100% - 2rem) / 3);
      object-fit: cover;
      border-radius: 20px;

      @media (max-width: 768px) {
        min-width: calc((100% - 0.5rem) / 2.4);
        max-width: calc((100% - 0.5rem) / 2.4);
        border-radius: 10px;
      }

      @media (max-width: 480px) {
        min-width: calc((100% - 0.5rem) / 2);
        max-width: calc((100% - 0.5rem) / 2);
        border-radius: 10px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider {
    display: flex;
    margin-top: 100px;
    width: 100%;
    overflow-x: scroll;
    width: 40%;
    margin: 0 auto;

    .card {
      height: 1px;
      background: none;
      min-width: calc(100% / 3);
      max-width: calc(100% / 3);

      @media (max-width: 768px) {
        min-width: calc(100% / 2.4);
        max-width: calc(100% / 2.4);
      }

      @media (max-width: 480px) {
        min-width: calc(100% / 2);
        max-width: calc(100% / 2);
      }
    }
  }

  .slider::-webkit-scrollbar {
    height: 2px;
    border-radius: 10px;
    background-color: #cdcdcd;
    cursor: pointer;

    @media (max-width: 480px) {
      height: 1px;
    }
  }

  .slider::-webkit-scrollbar-thumb {
    padding: 2px 0;
    background-color: #000;
    border-radius: 10px;
    cursor: pointer;
  }
}
