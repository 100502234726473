@import "../../../DesignSystem/library";

.work-with-container {
  background-color: white;
  border-radius: 30px;
  margin-top: 3rem;
  padding: 3rem;
  font-family: $font-primary;
  margin-bottom: 3rem;

  .wwc-title {
    font-size: 48px;
    color: $turquoise-100;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  .wwc-separator {
    height: 1px;
    width: 100%;
    background-color: $gray-500;
  }

  .wwc-desc {
    display: flex;
    justify-content: end;
    width: 100%;
    float: right;
    margin: 4rem 0rem;
    margin-bottom: 6rem;
    line-height: 24px;
    letter-spacing: 0.5px;

    P {
      font-weight: 300;
      font-size: 20px;
      width: 490px;
    }
  }

  .wwc-image-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 980px) {
      gap: 2.4rem;
    }

    .wwc-card {
      min-width: calc((100% - 4rem) / 3);
      max-width: calc((100% - 4rem) / 3);
      aspect-ratio: 1 / 0.8;
      object-fit: cover;
      position: relative;
      border-radius: 11px;
      background-color: white;
      transition: background-color linear 0.2s;

      @media (max-width: 980px) {
        min-width: calc((100% - 2.4rem) / 2);
        max-width: calc((100% - 2.4rem) / 2);
      }

      img {
        border-radius: 11px;
        filter: brightness(0.7) contrast(1.1); /* Reduce brightness to 80% */
      }

      .wwc-card-title {
        position: absolute;
        color: white;
        font-size: 36px;
        bottom: 2rem;
        left: 1.5rem;
        width: 300px;
        font-weight: 600;
      }

      p {
        color: $black-300;
        font-size: 15px;
        width: 80%;
        padding: 2rem;
        position: absolute;
        bottom: 0rem;
      }
    }

    .wwc-card-hovered {
      background-color: $gray-300;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    padding: 1.5rem;
    border-radius: 16px;

    .wwc-title {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    .wwc-image-container-mobile {
      display: flex;
      gap: 0.5rem;
      overflow-x: scroll;
      overflow-y: hidden;

      .wwc-card-mobile {
        min-width: 140px;
        width: 140px;
        height: 161px;
        border-radius: 11px;
        background-color: white;
        transition: background-color linear 0.2s;
        position: relative;
        margin-top: 2rem;

        img {
          border-radius: 11px;
          filter: brightness(0.7) contrast(1.1); /* Reduce brightness to 80% */
        }

        .wwc-card-title-mobile {
          position: absolute;
          color: white;
          font-size: 13.97px;
          bottom: 1rem;
          left: 1rem;
          width: 100px;
          font-weight: 600;
        }

        p {
          color: $black-300;
          font-size: 15px;
          width: 80%;
          padding: 2rem;
          position: absolute;
          bottom: 0rem;
        }
      }
    }
  }
}
