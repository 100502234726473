@import "../../../DesignSystem/library";

.leadership-container {
  background-color: #106666;
  font-family: $font-primary;
  padding: 3rem;
  border-radius: 32px;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 300px) and (max-width: 650px) {
    padding: 1rem 1rem;
    border-radius: 16px;
  }

  .leadership-header {
    position: relative;
    color: white;
    font-size: 48px;
    margin-bottom: 1.5rem;
    z-index: 111;

    @media screen and (min-width: 200px) and (max-width: 400px) {
      font-size: 20px;
      margin-bottom: 0.2rem;
      font-weight: 300;
    }
    @media screen and (min-width: 400px) and (max-width: 650px) {
      font-size: 26px;
      margin-bottom: 0.4rem;
      font-weight: 300;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: #a7a7a7;
  }

  .leadership-content-container {
    display: flex;
    margin-top: 4rem;

    @media screen and (min-width: 200px) and (max-width: 400px) {
      flex-direction: column;
      margin: 1rem 0rem;
    }

    @media screen and (min-width: 400px) and (max-width: 650px) {
      margin: 1rem 0rem;
      flex-direction: column;
    }

    .leadership-desc-wrapper {
      display: flex;

      .leadership-desc {
        color: white;
        width: 90%;
        line-height: 24px;
        font-size: 20px;
        z-index: 11;
        font-weight: 300;

        @media screen and (min-width: 200px) and (max-width: 400px) {
          font-size: 10px;
          line-height: 12px;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          font-size: 12px;
          line-height: 12px;
        }

        @media screen and (min-width: 650px) and (max-width: 900px) {
          font-size: 14px;
        }

        @media screen and (min-width: 900px) and (max-width: 1250px) {
          font-size: 16px;
        }
      }
    }

    .leaders-cards-container {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 100%;
      z-index: 11;
      position: relative;
      margin-top: 1rem;

      @media screen and (min-width: 300px) and (max-width: 650px) {
        gap: 1rem;
      }

      .leader-card-element-container {
        @media screen and (min-width: 200px) and (max-width: 650px) {
          width: 100%;
        }

        .leader-image-container {
          position: relative;

          img {
            width: 386px;
            height: 480px;
            border-radius: 20px;
            filter: grayscale(100%);
            object-fit: cover;

            @media screen and (min-width: 200px) and (max-width: 400px) {
              height: 160px;
              width: 100%;
              border-radius: 8px;
            }
            @media screen and (min-width: 400px) and (max-width: 650px) {
              height: 300px;
              width: 100%;
              border-radius: 8px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              width: 186px;
              height: 280px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              width: 286px;
              height: 380px;
            }
          }

          .redirection-container {
            position: absolute;
            bottom: 1.5rem;
            left: 1.5rem;
            height: 63px;
            width: 63px;
            border: 1px solid white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;

            @media screen and (min-width: 300px) and (max-width: 650px) {
              height: 23px;
              width: 23px;
              border-radius: 6px;
              bottom: 0.8rem;
              left: 0.6rem;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              height: 33px;
              width: 33px;
            }

            svg {
              @media screen and (min-width: 300px) and (max-width: 650px) {
                width: 6px;
                height: 6px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                width: 10px;
                height: 10px;
              }
              path {
                fill: white;
              }
            }
          }

          .redirection-container:hover {
            background-color: white;

            svg {
              path {
                fill: #106666;
              }
            }
          }

          .redirection-container:active {
            svg {
              path {
                fill: white;
              }
            }
          }
        }

        .leader-name {
          color: white;
          font-weight: 600;
          font-size: 32px;
          margin-top: 0.6rem;

          @media screen and (min-width: 300px) and (max-width: 650px) {
            font-size: 8px;
            margin-top: 0.2rem;
          }

          @media screen and (min-width: 650px) and (max-width: 900px) {
            font-size: 16px;
          }

          @media screen and (min-width: 900px) and (max-width: 1250px) {
            font-size: 22px;
          }
        }

        .leader-designation {
          color: white;
          font-weight: 300;
          margin-top: 0.3rem;

          @media screen and (min-width: 300px) and (max-width: 650px) {
            font-size: 6px;
          }

          @media screen and (min-width: 650px) and (max-width: 900px) {
            font-size: 10px;
          }

          @media screen and (min-width: 900px) and (max-width: 1250px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .fullwave-container {
    position: absolute;
    top: 0;
    right: 0rem;
    z-index: 0;
  }

  .leader-popup-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-family: $font-primary;
    padding: 3rem;
    border-radius: 32px;
    z-index: 111;
    transition: left 0.2s linear;

    @media screen and (min-width: 300px) and (max-width: 400px) {
      padding: 1rem;
    }

    @media screen and (min-width: 400px) and (max-width: 650px) {
      padding: 1.5rem;
    }

    .leader-card-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .leader-card-container {
        position: absolute;
        bottom: 0rem;
        width: 100%;
        height: 80%;
        background-color: white;
        border-radius: 32px;
        display: flex;

        @media screen and (min-width: 300px) and (max-width: 400px) {
          height: 100%;
          border-radius: 8px;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          height: 100%;
          border-radius: 8px;
        }

        .popup-leader-image-container {
          display: flex;
          align-items: end;
          position: relative;
          z-index: 22;

          img {
            width: 445px;
            height: 489px;
            filter: grayscale(100%);
            object-fit: cover;
            border-bottom-left-radius: 32px;
            z-index: 2;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 185px;
              height: 220px;
              border-bottom-left-radius: 8px;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              width: 185px;
              height: 220px;
              border-bottom-left-radius: 8px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              width: 295px;
              height: 344px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              width: 380px;
              height: 424px;
            }
          }

          .leader-details-strip {
            position: absolute;
            right: -50%;
            bottom: 2rem;
            background-color: rgba(40, 143, 144, 0.7);
            border-top-right-radius: 65px;
            height: 121px;
            width: 400px;
            z-index: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 1rem;
            padding-right: 0.5rem;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              height: 38px;
              width: 149px;
              padding-left: 0.8rem;
              bottom: 1rem;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              height: 55px;
              width: 209px;
              padding-left: 1rem;
              bottom: 1rem;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              height: 70px;
              width: 279px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              height: 80px;
              width: 329px;
            }

            .leader-name {
              font-size: 32px;
              color: white;

              @media screen and (min-width: 300px) and (max-width: 400px) {
                font-size: 12px;
              }

              @media screen and (min-width: 400px) and (max-width: 650px) {
                font-size: 16px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                font-size: 22px;
              }

              @media screen and (min-width: 900px) and (max-width: 1250px) {
                font-size: 28px;
              }
            }

            .leader-designation {
              font-size: 22px;
              color: white;
              font-weight: 300;

              @media screen and (min-width: 300px) and (max-width: 400px) {
                font-size: 8px;
              }

              @media screen and (min-width: 400px) and (max-width: 650px) {
                font-size: 12px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                font-size: 14px;
              }

              @media screen and (min-width: 900px) and (max-width: 1250px) {
                font-size: 18px;
              }
            }
          }
        }

        .leader-description-container {
          display: flex;
          align-items: center;
          margin-bottom: 6rem;

          p {
            width: 55%;
            margin-left: 4rem;
            font-size: 20px;
            line-height: 26px;
            font-weight: 300;
            letter-spacing: 0.6px;
            color: #151515;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 66%;
              margin-left: 0.5rem;
              font-size: 9px;
              line-height: 12px;
              margin-bottom: -3rem;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              width: 66%;
              margin-left: 0rem;
              font-size: 9px;
              line-height: 12px;
              margin-bottom: -2rem;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              width: 65%;
              margin-left: 0rem;
              font-size: 12px;
              line-height: 16px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              width: 60%;
              margin-left: 2rem;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .popup-close-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #106666;
          position: absolute;
          right: 1.5rem;
          top: 1.5rem;
          height: 53px;
          width: 53px;
          border-radius: 11px;
          cursor: pointer;

          @media screen and (min-width: 300px) and (max-width: 400px) {
            right: 0.7rem;
            top: 0.7rem;
            height: 29px;
            width: 29px;
            border-radius: 8px;
          }

          @media screen and (min-width: 400px) and (max-width: 650px) {
            right: 1rem;
            top: 1rem;
            height: 34px;
            width: 34px;
            border-radius: 8px;
          }

          @media screen and (min-width: 650px) and (max-width: 900px) {
            height: 38px;
            width: 38px;
          }

          @media screen and (min-width: 900px) and (max-width: 1250px) {
            height: 48px;
            width: 48px;
          }

          svg {
            height: 14px;
            width: 14px;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              height: 8px;
              width: 8px;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              height: 10px;
              width: 10px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              height: 10px;
              width: 10px;
            }

            path {
              fill: #106666;
            }
          }
        }

        .popup-close-icon-container:hover {
          background-color: #106666;

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .nayani-card-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .nayani-card-container {
        position: absolute;
        bottom: 0rem;
        width: 100%;
        height: 80%;
        background-color: white;
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 300px) and (max-width: 400px) {
          height: 100%;
          border-radius: 8px;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          height: 100%;
          border-radius: 8px;
        }

        .nayani-description-container {
          display: flex;
          align-items: center;
          padding: 1rem 0rem 0rem 1rem;
          // margin-top: 4rem;

          p {
            width: 65%;
            font-size: 20px;
            line-height: 26px;
            font-weight: 300;
            letter-spacing: 0.6px;
            color: #151515;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              font-size: 9px;
              line-height: 9px;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              font-size: 9px;
              line-height: 12px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              width: 65%;
              font-size: 12px;
              line-height: 16px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              width: 60%;
              font-size: 16px;
              line-height: 22px;
            }
          }

          .popup-close-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #106666;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            height: 53px;
            width: 53px;
            border-radius: 11px;
            cursor: pointer;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              right: 0.7rem;
              top: 0.7rem;
              height: 29px;
              width: 29px;
              border-radius: 8px;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              right: 1rem;
              top: 1rem;
              height: 34px;
              width: 34px;
              border-radius: 8px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              height: 38px;
              width: 38px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              height: 48px;
              width: 48px;
            }

            svg {
              height: 14px;
              width: 14px;

              @media screen and (min-width: 300px) and (max-width: 400px) {
                height: 8px;
                width: 8px;
              }

              @media screen and (min-width: 400px) and (max-width: 650px) {
                height: 10px;
                width: 10px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                height: 10px;
                width: 10px;
              }

              path {
                fill: #106666;
              }
            }
          }

          .popup-close-icon-container:hover {
            background-color: #106666;

            svg {
              path {
                fill: white;
              }
            }
          }
        }

        .popup-nayani-image-container {
          display: flex;
          align-items: end;
          position: relative;
          z-index: 22;

          img {
            width: 445px;
            height: 489px;
            filter: grayscale(100%);
            object-fit: cover;
            border-bottom-left-radius: 32px;
            z-index: 2;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 145px;
              height: 180px;
              border-bottom-left-radius: 8px;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              width: 185px;
              height: 220px;
              border-bottom-left-radius: 8px;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              width: 295px;
              height: 344px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              width: 380px;
              height: 424px;
            }
          }

          .nayani-details-strip {
            position: absolute;
            left: 20%;
            bottom: 2rem;
            background-color: rgba(40, 143, 144, 0.7);
            border-top-right-radius: 25px;
            height: 121px;
            width: 400px;
            z-index: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 1rem;
            padding-right: 0.5rem;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              height: 38px;
              width: 169px;
              padding-left: 0.8rem;
              bottom: 1rem;
            }

            @media screen and (min-width: 400px) and (max-width: 650px) {
              height: 55px;
              width: 209px;
              padding-left: 1rem;
              bottom: 1rem;
            }

            @media screen and (min-width: 650px) and (max-width: 900px) {
              height: 70px;
              width: 279px;
            }

            @media screen and (min-width: 900px) and (max-width: 1250px) {
              height: 80px;
              width: 329px;
            }

            .nayani-name {
              font-size: 32px;
              color: white;
              text-wrap: nowrap;

              @media screen and (min-width: 300px) and (max-width: 400px) {
                font-size: 12px;
              }

              @media screen and (min-width: 400px) and (max-width: 650px) {
                font-size: 16px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                font-size: 22px;
              }

              @media screen and (min-width: 900px) and (max-width: 1250px) {
                font-size: 28px;
              }
            }

            .nayani-designation {
              font-size: 22px;
              color: white;
              font-weight: 300;

              @media screen and (min-width: 300px) and (max-width: 400px) {
                font-size: 8px;
              }

              @media screen and (min-width: 400px) and (max-width: 650px) {
                font-size: 12px;
              }

              @media screen and (min-width: 650px) and (max-width: 900px) {
                font-size: 14px;
              }

              @media screen and (min-width: 900px) and (max-width: 1250px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.scott-card-container {
  width: 100%;
  border-radius: 35px;
  background-color: white;
  padding: 3rem;

  @media screen and (min-width: 300px) and (max-width: 800px) {
    border-radius: 16px;
    padding: 1rem;
  }

  .scott-card-header {
    .scott-heading {
      font-size: 48px;
      color: $turquoise-100;
      margin-bottom: 1.5rem;

      @media screen and (min-width: 300px) and (max-width: 400px) {
        font-size: 20px;
        margin-bottom: 0.5rem;
        font-weight: 300;
      }

      @media screen and (min-width: 400px) and (max-width: 650px) {
        font-weight: 300;
        font-size: 26px;
        margin-bottom: 0.5rem;
      }

      @media screen and (min-width: 650px) and (max-width: 900px) {
        font-size: 30px;
      }

      @media screen and (min-width: 900px) and (max-width: 1250px) {
        font-size: 38px;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: #a7a7a7;
    }
  }

  .scott-card-content {
    display: flex;
    position: relative;

    .scott-image-container {
      position: relative;
      margin-top: 2rem;
      display: flex;

      img {
        width: 380px;
        height: 498px;
        object-fit: cover;
        border-bottom-left-radius: 35px;

        @media screen and (min-width: 300px) and (max-width: 400px) {
          width: 150px;
          height: 180px;
          border-bottom-left-radius: 8px;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          width: 170px;
          height: 228px;
          border-bottom-left-radius: 8px;
        }

        @media screen and (min-width: 650px) and (max-width: 900px) {
          width: 280px;
          height: 398px;
          border-bottom-left-radius: 8px;
        }

        @media screen and (min-width: 900px) and (max-width: 1250px) {
          width: 306px;
          height: 470px;
        }
      }

      .scott-details-strip {
        position: absolute;
        border-top-right-radius: 25px;
        background-color: rgba(40, 143, 144, 0.7);
        width: 327px;
        height: 101px;
        bottom: 2rem;
        right: -50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2rem;
        color: white;

        @media screen and (min-width: 200px) and (max-width: 400px) {
          width: 140px;
          height: 45px;
          padding-left: 1rem;
          bottom: 1rem;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          width: 160px;
          height: 45px;
          padding-left: 0.5rem;
          bottom: 1rem;
        }

        @media screen and (min-width: 650px) and (max-width: 850px) {
          width: 260px;
          height: 81px;
        }

        @media screen and (min-width: 850px) and (max-width: 950px) {
          width: 300px;
          height: 91px;
        }

        @media screen and (min-width: 950px) and (max-width: 1100px) {
        }

        .scott-name {
          font-size: 32px;
          font-weight: 500;

          @media screen and (min-width: 200px) and (max-width: 400px) {
            font-size: 14px;
          }

          @media screen and (min-width: 400px) and (max-width: 650px) {
            font-size: 16px;
          }

          @media screen and (min-width: 650px) and (max-width: 850px) {
            font-size: 26px;
          }
        }

        .scott-designation {
          font-size: 22px;
          font-weight: 300;

          @media screen and (min-width: 200px) and (max-width: 400px) {
            font-size: 8px;
          }

          @media screen and (min-width: 400px) and (max-width: 650px) {
            font-size: 10px;
          }

          @media screen and (min-width: 650px) and (max-width: 850px) {
            font-size: 16px;
          }
        }
      }
    }

    .scott-desc-container {
      display: flex;
      align-items: center;
      p {
        width: 55%;
        margin-left: 4rem;
        margin-bottom: 10rem;
        font-size: 20px;
        line-height: 26px;
        font-weight: 300;
        letter-spacing: 0.6px;
        color: #151515;

        @media screen and (min-width: 200px) and (max-width: 400px) {
          font-size: 9px;
          line-height: 9px;
          width: 100%;
          margin-left: 0rem;
          margin-bottom: 4rem;
          height: 130px;
        }

        @media screen and (min-width: 400px) and (max-width: 650px) {
          font-size: 8px;
          line-height: 12px;
          width: 75%;
          margin-left: 1rem;
          margin-bottom: 4rem;
        }
        @media screen and (min-width: 650px) and (max-width: 850px) {
          font-size: 12px;
          line-height: 20px;
          width: 75%;
          margin-left: 1rem;
          margin-bottom: 7rem;
        }

        @media screen and (min-width: 850px) and (max-width: 950px) {
          font-size: 14px;
          line-height: 22px;
        }

        @media screen and (min-width: 950px) and (max-width: 1100px) {
          font-size: 16px;
        }
      }
    }
  }
}
