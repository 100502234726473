@import "../../../DesignSystem//library";

.who-we-work-container {
  @media (max-width: 480px) {
    margin-top: -2rem;
    margin-bottom: 0.5rem;
  }
  .title {
    font-size: 50px;
    font-weight: 500;
    color: $turquoise-100;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 38px;
    }

    @media (max-width: 480px) {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: -0.5rem;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1rem 0;
    @media (max-width: 480px) {
      margin-bottom: -1.5rem;
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.5rem;
    margin-top: 2.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    .card {
      flex: 1 1 100px;
      height: 200px;
      border-radius: 8px;
      background-color: $turquoise-100;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      box-sizing: border-box;
      text-align: center;

      @media (max-width: 768px) {
        height: 180px;
        width: 90px;
      }
      @media (max-width: 480px) {
        height: 90px;
        min-width: 90px !important;
      }

      @media (max-width: 375px) {
        height: 110px;
        width: 90px;
      }
      @media (max-width: 320px) {
        height: 90px;
        width: 90px;
      }

      .img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 20px;
        right: 20px;

        @media (max-width: 1200px) {
          width: 50px;
          height: 50px;
        }

        @media (max-width: 768px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 480px) {
          width: 20px;
          height: 20px;
          top: 10px;
          right: 10px;
        }

        @media (max-width: 414px) {
          width: 25px;
          height: 25px;
          top: 12px;
          right: 10px;
        }

        @media (max-width: 375px) {
          width: 20px;
          height: 20px;
          top: 12px;
          right: 10px;
        }
        @media (max-width: 320px) {
          width: 18px;
          height: 18px;
          top: 12px;
          right: 10px;
        }
      }

      .title {
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        bottom: 20px;
        left: 20px;
        text-align: left;
        margin: 0;
        margin-bottom: 1rem;

        @media (max-width: 1200px) {
          font-size: 20px;
          bottom: 15px;
          left: 15px;
        }

        @media (max-width: 768px) {
          font-size: 18px;
          bottom: 10px;
          left: 10px;
        }

        @media (max-width: 480px) {
          font-size: 10px;
          bottom: 2px;
          left: 8px;
          font-weight: 520;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
