@import "../../DesignSystem/library";

.event-info-container {
  background-color: #106666;
  border-radius: 32px;
  position: relative;
  padding: 3rem;
  font-family: $font-primary;
  overflow: hidden;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    padding: 1.5rem;
    border-radius: 10px;
  }

  .info {
    position: relative;
    color: #ffffff;
    z-index: 11;

    .title {
      font-size: 48px;
      font-weight: 500;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .desc {
      font-size: 20px;
      margin-top: 1rem;
      font-weight: 400;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 10px;
        margin-top: 0.2rem;
        font-weight: 500;
      }
    }
  }

  .event-session-container {
    display: flex;
    gap: 1.5rem;
    margin-top: 4rem;
    height: 190px;
    z-index: 10;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      gap: 0.5rem;
      margin-top: 0.5rem;
      height: auto;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    .event-details {
      background-color: #ffffff;
      flex: 1;
      z-index: 10;

      .card-content {
        height: 100%;
        padding: 1.5rem 2rem;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          padding: 0.5rem 0.5rem;
          border-radius: 0px;
        }

        .card-title {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          color: $turquoise-100;
          font-size: 24px;
          letter-spacing: 0.5px;

          .register-btn {
            background-color: black;
            color: #ffffff;
            font-weight: 400;
            letter-spacing: 0.5px;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              width: 55px;
              height: 18px;
              font-size: 7px;
              border-radius: 1.7px;
              letter-spacing: 0.4px;
            }

            @media screen and (min-width: 600px) and (max-width: 1000px) {
              width: 80px;
              height: 24px;
              font-size: 10px;
              border-radius: 1.7px;
              letter-spacing: 0.4px;
            }
          }

          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 11px;
            letter-spacing: 0.2px;
          }
        }

        .separator {
          height: 1px;
          width: 100%;
          background-color: $gray-500;
          margin-bottom: 1.4rem;
          margin-top: 0.5rem;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: 0.6rem;
            height: 0.28px;
            margin-top: 0.3rem;
          }
        }

        .card-info {
          .card-date {
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.5px;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
            }
          }

          .card-address {
            margin-top: 0.7rem;
            font-weight: 300;
            color: $turquoise-100;
            letter-spacing: 0.5px;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
              margin-top: 0.3rem;
            }
          }
        }
      }
    }
  }

  .top-wave-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .bottom-wave-container {
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    z-index: 0;
  }
}
