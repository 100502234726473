@import "../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 0.8rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }
}

.header {
  position: relative;

  .header-img {
    width: 100%;
    height: 609px;
    object-fit: cover;
    border-radius: 30px;
    filter: brightness(0.8) contrast(1.1);

    @media (max-width: 768px) {
      border-radius: 20px;
      height: 60vh;
    }

    @media (max-width: 480px) {
      border-radius: 20px;
      height: 50vh;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 98px;
    font-weight: 300;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 64px;
    }

    @media (max-width: 480px) {
      font-size: 42px;
    }
  }
}

.story {
  width: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 5rem 10rem 7rem 10rem;
  background-color: #106666;
  border-radius: 30px;

  @media (max-width: 768px) {
    padding: 2.5rem 1.2rem 2.5rem 1.2rem;
    border-radius: 16px;
  }

  @media (max-width: 480px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
    border-radius: 16px;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 2rem;
    }

    @media (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }

  .text {
    font-size: 30px;
    line-height: 40px;
    font-weight: 250;
    position: relative;
    z-index: 1;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
    }
  }

  .top-wave-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    @media (max-width: 768px) {
      svg {
        width: 520px;
        height: 280px;
      }
    }

    @media (max-width: 480px) {
      svg {
        width: 350px;
        height: 150px;
      }
    }
  }

  .bottom-wave-container {
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    z-index: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.ceo-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  font-weight: 500;
  position: relative;

  @media (max-width: 768px) {
    gap: 2rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
  }

  .ceoImg {
    border-radius: 20px;
    filter: grayscale(100%);
    transition: filter 0.3s ease, transform 0.3s ease;
    object-fit: cover;
    width: 436px;
    height: 486px;

    // @media (max-width: 768px) {
    //   max-width: 28%;
    //   border-radius: 4px;
    // }

    // @media (max-width: 480px) {
    //   max-width: 30%;
    //   border-radius: 4px;
    // }

    @media screen and (min-width: 300px) and (max-width: 400px) {
      width: 126px;
      height: 206px;
    }

    @media screen and (min-width: 400px) and (max-width: 550px) {
      width: 176px;
      height: 236px;
    }
    @media screen and (min-width: 550px) and (max-width: 650px) {
      width: 376px;
      height: 356px;
    }

    @media screen and (min-width: 650px) and (max-width: 900px) {
      width: 436px;
      height: 456px;
    }

    @media screen and (min-width: 900px) and (max-width: 1250px) {
      width: 436px;
      height: 456px;
    }
  }

  .ceoImg:hover {
    // filter: grayscale(0%);
    transform: scale(0.998);
  }

  .quote {
    font-size: 250px;
    color: $turquoise-100;
    height: 95px;
    text-indent: 0;
    position: relative;
    bottom: 28px;

    @media screen and (min-width: 300px) and (max-width: 400px) {
      font-size: 50px;
      height: 16px;
      bottom: 12px;
    }

    @media screen and (min-width: 400px) and (max-width: 550px) {
      font-size: 70px;
      height: 20px;
      bottom: 18px;
    }
    @media screen and (min-width: 550px) and (max-width: 650px) {
      font-size: 110px;
      height: 30px;
      bottom: 28px;
    }

    @media screen and (min-width: 650px) and (max-width: 900px) {
      font-size: 110px;
      height: 30px;
      bottom: 28px;
    }
  }

  .main-text {
    color: #000;
    font-size: 48px;
    font-weight: 300;
    line-height: 60px;
    margin-bottom: 2.2rem;

    span {
      font-weight: 600;
      color: $turquoise-100;
    }

    // @media (max-width: 768px) {
    //   font-size: 30px;
    //   line-height: 35px;
    //   margin-bottom: 1.2rem;
    //   width: unset;
    // }

    // @media (max-width: 480px) {
    //   font-size: 14px;
    //   line-height: 17px;
    //   margin-bottom: 0.8rem;
    //   width: unset;
    // }
    @media screen and (min-width: 300px) and (max-width: 400px) {
      font-size: 16px;
      line-height: 18px;
      width: unset;
      margin-bottom: 1.2rem;
    }

    @media screen and (min-width: 400px) and (max-width: 550px) {
      font-size: 20px;
      line-height: 26px;
      width: unset;
    }
    @media screen and (min-width: 550px) and (max-width: 650px) {
      font-size: 26px;
      line-height: 32px;
      width: unset;
    }

    @media screen and (min-width: 650px) and (max-width: 900px) {
      font-size: 34px;
      line-height: 40px;
      width: unset;
    }

    @media screen and (min-width: 900px) and (max-width: 1250px) {
      font-size: 40px;
      line-height: 45px;
      width: unset;
    }
  }

  .ceo-name,
  .ceo-designation {
    font-size: 26px;
    letter-spacing: 0.5px;
    color: $turquoise-100;
    text-wrap: nowrap;
    margin-top: 0.4rem;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  .ceo-designation {
    font-size: 20px;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      margin-top: 0rem;
      font-size: 8px;
    }
  }
}

.mission-vision-container {
  display: flex;
  align-items: stretch;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    gap: 10px;
    flex-direction: column;
  }

  .card {
    flex: 1;
    background-color: #fff;
    color: $turquoise-100;
    border-radius: 30px;
    padding: 3rem;

    @media (max-width: 768px) {
      padding: 1rem;
      border-radius: 16px;
    }

    @media (max-width: 480px) {
      padding: 1rem;
      border-radius: 16px;
    }

    .title {
      font-size: 48px;
      font-weight: 500;
      border-bottom: 1px solid #a7a7a7;
      padding-bottom: 1.5rem;

      @media (max-width: 768px) {
        font-size: 30px;
        padding-bottom: 1rem;
      }

      @media (max-width: 550px) {
        font-size: 26px;
        padding-bottom: 0.6rem;
        border-bottom: 0.5px solid #a7a7a7;
        font-weight: 300;
      }
    }

    .text {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      color: $black-300;
      width: 70%;
      margin-left: auto;
      margin-top: 12rem;
      letter-spacing: 0.5px;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 15px;
        width: 75%;
        margin-top: 4rem;
      }

      @media (max-width: 480px) {
        font-size: 10px;
        line-height: 12px;
        width: 80%;
        margin-top: 2.5rem;
      }
    }
  }
}

.leadership-team {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;
  padding-bottom: 4rem;

  @media (max-width: 768px) {
    border-radius: 20px;
    background-color: #fff;
    padding: 2rem;
  }

  @media (max-width: 480px) {
    border-radius: 20px;
    background-color: #fff;
    padding: 1.5rem;
  }

  .title {
    font-size: 48px;
    font-weight: 500;
    border-bottom: 1px solid #a7a7a7;
    padding-bottom: 1.5rem;
    color: $turquoise-100;

    @media (max-width: 768px) {
      font-size: 28px;
      padding-bottom: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 0.5rem;
      border-bottom: 0.5px solid #a7a7a7;
    }
  }

  .founders-details {
    width: 80%;
    margin: 4rem auto 0 auto;

    @media (max-width: 768px) {
      margin-top: 1.5rem;
      width: 90%;
    }

    @media (max-width: 480px) {
      margin-top: 0.8rem;
      width: 100%;
    }

    .founder {
      display: flex;
      align-items: center;
      gap: 80px;

      @media (max-width: 768px) {
        gap: 50px;
      }

      @media (min-width: 480px) and (max-width: 650px) {
        gap: 40px;
      }

      @media (max-width: 480px) {
        gap: 30px;
      }

      .founder-img {
        max-width: 30%;
        height: 320px;
        border-radius: 20px;
        object-fit: cover;
        @media (max-width: 768px) {
          max-width: 28%;
          height: 200px;
          border-radius: 15px;
        }

        @media (min-width: 480px) and (max-width: 650px) {
          max-width: 40%;
          height: 190px;
          border-radius: 15px;
        }

        @media (max-width: 480px) {
          max-width: 35%;
          height: unset;
          aspect-ratio: 1 / 1.2;
          border-radius: 10px;
        }

        @media (max-width: 390px) {
          width: 42%;
          height: unset;
          aspect-ratio: 1 / 1.25;
          border-radius: 10px;
        }
      }

      .founder-details {
        .founder-name {
          font-size: 48px;
          line-height: 60px;
          font-weight: 600;
          color: $turquoise-100;

          @media (max-width: 768px) {
            font-size: 30px;
            line-height: 35px;
            width: unset;
          }

          @media (min-width: 480px) and (max-width: 650px) {
            font-size: 18px;
            line-height: 22px;
            width: unset;
          }

          @media (max-width: 480px) {
            font-size: 14px;
            line-height: 17px;
            width: unset;
          }
        }

        .founder-role {
          font-size: 24px;
          letter-spacing: 0.5px;
          color: $turquoise-100;
          font-weight: 500;
          margin-bottom: 30px;

          @media (max-width: 768px) {
            font-size: 16px;
            margin-bottom: 20px;
          }

          @media (min-width: 480px) and (max-width: 650px) {
            font-size: 14px;
            margin-bottom: 15px;
          }

          @media (max-width: 480px) {
            font-size: 10px;
            margin-top: 2px;
            margin-bottom: 10px;
          }
        }

        .founder-experience {
          font-size: 20px;
          line-height: 24px;
          font-weight: 300;
          color: #151515;
          letter-spacing: 0.5px;

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 15px;
          }

          @media (max-width: 480px) {
            font-size: 8px;
            line-height: 9px;
          }
        }
      }
    }

    .founder-right {
      flex-direction: row-reverse;
      margin-top: 60px;

      @media (min-width: 650px) and (max-width: 1024px) {
        margin-top: 40px;
      }

      @media (min-width: 480px) and (max-width: 650px) {
        margin-top: 35px;
      }

      @media (max-width: 480px) {
        margin-top: 14px;
        flex-direction: row;
      }
    }
  }
}
