@import "../../../../../DesignSystem/library";

.breakout-session-container {
  font-family: $font-primary;

  .register-now-container {
    position: relative;
    border-radius: 20px;
    height: 234px;
    background-color: $blue-200;
    padding: 2rem 3rem;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      border-radius: 6px;
      padding: 1rem 1.5rem;
      height: 114px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
      height: 174px;
    }

    .rnc-title {
      font-size: 24px;
      font-weight: 600;
      width: 80%;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 10px;
        width: 100%;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 16px;
        line-height: 1.2rem;
        width: 100%;
      }
    }

    .rnc-desc {
      font-size: 20px;
      font-weight: 300;
      margin-top: 0.5rem;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 15px;
        margin-top: 0.2rem;
      }
    }

    .rnc-date {
      font-weight: 400;
      color: $black-300;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      letter-spacing: 0.5px;
      font-size: 18px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 12px;
        margin-top: 0.4rem;
        margin-bottom: 0.2rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 15px;
        margin-top: 0.2rem;
      }
    }

    .rnc-format {
      color: $turquoise-100;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 12px;
      }
    }

    .rnc-address {
      color: $turquoise-100;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-top: 0.7rem;
      font-weight: 300;
      width: 60%;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
        width: 80%;
        margin-top: 0.2rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 0.4rem;
        width: 80%;
      }
    }
  }

  .agenda-container {
    margin-top: 1.5rem;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      margin-top: 0.7rem;
    }

    .agenda-separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin: 1rem 0rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        height: 0.28px;
        margin: 0.5rem 0rem;
      }
    }
    .speaker-name {
      color: $turquoise-100;
      font-weight: 600;
    }

    .panelist-container {
      display: flex;
      justify-content: space-between;

      .svg-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        flex: 1;
        align-items: end;
        justify-content: end;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          gap: 0.5rem;
          margin-left: 1rem;
        }
        .se-logo-container {
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: -0.5rem;
          }
        }

        div {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 2rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            gap: 1rem;
          }

          svg {
            width: 180px;
            height: 100px;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              width: 45px;
              height: 38px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              width: 75px;
              height: 38px;
            }
          }
        }
      }
    }

    .agenda-title {
      display: flex;
      justify-content: space-between;
      font-size: 36px;
      color: $turquoise-100;
      font-weight: 300;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 10px;
      }

      .events-btn-container {
        display: flex;
        gap: 1rem;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          gap: 0.3rem;
        }

        .events-button {
          font-size: 14px;
          width: 114px;
          height: 37.26px;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 6px;
            width: 45px;
            height: 13px;
            border-radius: 2px;
            padding: 2.8px 5.7px;
          }
        }
      }
    }

    .timeline-info-container {
      .title-time-container {
        display: flex;
        justify-content: space-between;

        .timeline-title {
          font-size: 20px;
          font-weight: 600;
          width: 80%;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 10px;
            width: 240px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 15px;
            width: 80%;
          }
        }

        .timeline-time {
          font-size: 18px;
          font-weight: 300;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 8px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 14px;
          }
        }
      }

      .timeline-desc {
        font-size: 18px;
        color: $black-300;
        margin-top: 0.9rem;
        // border: 1px solid red;
        width: 700px;
        line-height: 24px;
        font-weight: 300;
        letter-spacing: 0.5px;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 8px;
          margin-top: 0.2rem;
          line-height: 8px;
          width: 80%;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 14px;
          margin-top: 0.4rem;
          line-height: 18px;
          width: 80%;
        }
      }
    }

    .blue-strip-container {
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      background-color: $blue-200;
      padding: 1rem;
      @media screen and (min-width: 0px) and (max-width: 600px) {
        padding: 0.3rem 0.5rem;
        border-radius: 3.5px;
      }

      .bs-title {
        color: $turquoise-100;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.5px;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 10px;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 15px;
        }
      }

      .bs-time {
        font-size: 18px;
        font-weight: 300;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 8px;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 13px;
        }
      }
    }

    .people-info-contaier {
      margin-top: 1.5rem;
      @media screen and (min-width: 0px) and (max-width: 600px) {
        margin-top: 0.5rem;
      }
      .pi-title {
        font-size: 20px;
        font-weight: 300;
        color: $turquoise-100;
        margin-bottom: 0.7rem;
        letter-spacing: 0.5px;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 10px;
          margin-bottom: 0.3rem;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 15px;
        }
      }

      .pi-desc {
        margin-left: 1.5rem;
        letter-spacing: 0.5px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        justify-content: space-between;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          margin-left: 0.5rem;
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          margin-left: 0.5rem;
          grid-template-columns: repeat(4, 1fr);
        }
        .image-container {
          display: flex;
          align-items: center;
          flex-direction: column;
          .type-content {
            font-size: 12px;
            font-weight: 550;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 6px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 10px;
            }
          }
          .panelist-name {
            cursor: pointer;
            color: $turquoise-100;
            font-weight: 400;
            font-size: 16px;
            padding-top: 4px;
            padding-bottom: 6px;
            text-align: center;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 12px;
            }
          }
          .description {
            font-size: 12px;
            text-align: center;
            line-height: 15px;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
              line-height: 10px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 10px;
              line-height: 12px;
            }
          }
          // grid-template-columns: repeat(6, 1fr);
        }

        li {
          margin-top: 0.4rem;
          font-weight: 300;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-top: 0.4rem;
            font-size: 8px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 12px;
          }

          span {
            cursor: pointer;
            color: $turquoise-100;
            font-weight: 400;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 12px;
            }
          }
        }

        li::marker {
          color: $turquoise-100;
          font-size: 14px;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 6px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 10px;
          }
        }
      }
    }

    .register-btn-container {
      display: flex;
      justify-content: end;
      .register-container {
        font-size: 14px;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 6px;
          padding: 2.85px 5.7px;
          border-radius: 2px;
        }
      }
    }
  }
}
