@import "../../DesignSystem/library";

.events-page-container {
  padding: 2rem;
  padding-top: 1.5rem;
  font-family: $font-primary;
  background-color: #f6f8fb;

  .header {
    position: relative;

    .header-img {
      width: 100%;
      height: 609px;
      object-fit: cover;
      border-radius: 30px;
      filter: brightness(0.4);

      @media (max-width: 768px) {
        border-radius: 20px;
        height: 60vh;
      }

      @media (max-width: 480px) {
        border-radius: 20px;
        height: 50vh;
      }
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 128px;
      font-weight: 300;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 64px;
      }

      @media (max-width: 480px) {
        font-size: 42px;
      }
    }
  }

  .events-parent-container {
    background-color: white;
    border-radius: 30px;
    padding: 3rem;
    margin-top: 3rem;

    .events-upcoming-events {
      font-size: 48px;
      color: $turquoise-100;
      font-weight: 300;
    }

    .separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin: 1rem 0rem;
    }

    .events-container {
      .event-ele-separator-container {
        .event-ele-container {
          display: flex;
          gap: 5rem;
          justify-content: space-between;
          padding: 1rem 0rem;
          cursor: pointer;

          .event-date {
            font-size: 22px;
            letter-spacing: 0.5px;
            white-space: nowrap;
            width: 15%;
            max-width: 240px;

            @media (max-width: 850px) {
              min-width: 20%;
            }
          }

          .event-img {
            min-width: 300px;
            width: 398px;
            height: 265px;
            border-radius: 12px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              object-fit: cover;
            }
          }

          .event-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .event-info-date {
              display: none;
            }

            .event-title {
              color: $black-300;
              font-size: 22px;
            }

            .event-desc {
              font-size: 20px;
              font-weight: 300;
              color: $gray-600;
              margin-top: 0.6rem;
            }

            .event-link-container {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .events-page-container {
    padding: 1.5rem;

    .events-image-container {
      height: 609px;
      img {
        border-radius: 30px;
        object-fit: cover;
        filter: brightness(0.55) contrast(1.3);
      }
      .events-text {
        font-size: 64px;
        white-space: nowrap;
      }
    }

    .events-parent-container {
      border-radius: 10px;
      padding: 1.5rem;
      margin-top: 1.5rem;

      .events-upcoming-events {
        font-size: 28px;
        font-weight: 300;
      }

      .separator {
        height: 1px;
        margin: 1rem 0rem;
      }

      .events-container {
        .event-ele-separator-container {
          .event-ele-container {
            gap: 1.5rem;
            padding: 0.8rem 0 0.3rem 0;

            .event-date {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.5px;
              white-space: nowrap;
            }

            .event-img {
              flex-grow: 1;
              min-width: 110px;
              max-height: 140px;
              border-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
              }
            }

            .event-info {
              .event-title {
                font-size: 12px;
                line-height: 14px;
              }

              .event-desc {
                font-size: 10px;
                line-height: 12px;
                width: unset;
                margin-top: 0.4rem;
              }

              .event-link-container {
                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .events-page-container {
    padding: 0.8rem 1rem 1.2rem 1rem;

    .events-parent-container {
      border-radius: 10px;
      padding: 1.5rem;
      margin-top: 1rem;

      .events-upcoming-events {
        font-size: 14px;
        font-weight: 300;
      }

      .separator {
        height: 0.5px;
        margin: 0.5rem 0rem;
      }

      .events-container {
        .event-ele-separator-container {
          .event-ele-container {
            gap: 1.2rem;
            padding: 0.8rem 0 0.3rem 0;

            .event-date {
              display: none;
            }

            .event-img {
              width: 45%;
              min-width: 45%;
              max-height: 90px;
              border-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
              }
            }

            .event-info {
              .event-info-date {
                display: unset;
                font-size: 10px;
                line-height: 11px;
                letter-spacing: 0.5px;
                white-space: nowrap;
              }

              .event-title {
                font-size: 12px;
                line-height: 13px;
              }

              .event-desc {
                font-size: 8px;
                line-height: 9px;
                width: unset;
                margin-top: 0.4rem;
              }

              .event-link-container {
                svg {
                  width: 6px;
                  height: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}
