$blue-100: #1868db;
$blue-200: #eaf3ff;
$blue-300: #f8f8f9;

$turquoise-100: #288f90;

$gray-100: #f7f8fa;
$gray-200: #f8f9f9;
$gray-300: #eeeeee;
$gray-500: #a7a7a7;
$gray-600: #6d6d6d;
$gray-700: #d9d9d9;
$gray-800: #b7b7b7;
$gray-900: #a2a2a2;
$gray-1000: #d7d7d7;

$black-100: #151515;
$black-200: #0e0e0e;
$black-300: #4d4d4d;
