@import "../../../DesignSystem/library";

.event-desc-container {
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.8rem 1rem 1.2rem 1rem;
  }

  .header {
    position: relative;

    .header-img {
      width: 100%;
      height: 609px;
      object-fit: cover;
      border-radius: 30px;
      filter: brightness(0.6);

      @media (max-width: 768px) {
        border-radius: 20px;
        height: 60vh;
      }

      @media (max-width: 480px) {
        border-radius: 20px;
        height: 50vh;
      }
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 128px;
      font-weight: 300;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 64px;
      }

      @media (max-width: 480px) {
        font-size: 42px;
      }
    }
  }

  .events-image-container {
    height: 609px;
    @media screen and (min-width: 0px) and (max-width: 600px) {
      height: 509px;
    }
    img {
      border-radius: 30px;
      object-fit: cover;
      filter: brightness(0.55) contrast(1.3);
    }
    .events-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      color: white;
      font-size: 128px;
      font-weight: 300;
      @media screen and (min-width: 0px) and (max-width: 380px) {
        font-size: 43px;
        top: 45%;
        left: 50%;
      }
      @media screen and (min-width: 380px) and (max-width: 500px) {
        font-size: 43px;
        top: 35%;
        left: 50%;
      }
      @media screen and (min-width: 500px) and (max-width: 600px) {
        font-size: 43px;
        top: 43%;
        left: 50%;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 80px;
        top: 35%;
        left: 50%;
      }
      @media screen and (min-width: 1000px) and (max-width: 1200px) {
        font-size: 80px;
        top: 30%;
        left: 50%;
      }
    }
  }
}
