@import "../../DesignSystem/library";

.landingpage-container {
  padding: 1.5rem 2rem 3rem 2rem;
  background-color: $gray-200;

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0.8rem 1rem 1.2rem 1rem;
  }
}
