@import "../../../DesignSystem/library";

.our-vision-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-family: $font-primary;
  background-color: white;
  border-radius: 30px;
  padding: 3rem;
  margin-top: 3rem;
  position: relative;

  .our-vision-comment-container {
    color: $turquoise-100;
    text-align: center;
    display: flex;
    width: 100%;

    .comment-svg-left {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: fit-content;
      z-index: 0;
    }

    .our-vision-text {
      text-align: center;
      width: 100%;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 20px;
      margin: 2rem 0rem;
    }
  }

  .our-vision-para {
    text-align: center;
    font-size: 64px;
    font-weight: 300;
    letter-spacing: 1px;
    width: 70vw;
    margin-top: 1rem;
    padding-bottom: 4rem;

    .first-para {
      p {
        color: $turquoise-100;
        font-weight: 500;
      }
      span{
        // color: grenn;
        color: $turquoise-100;
        font-weight: 500;

      }
    }

    .second-para {
      margin-top: 3rem;
    }
  }

  .comment-svg-right {
    display: flex;
    justify-content: start;
    width: 100%;
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .card-container {
    display: flex;
    margin-top: 4rem;
    gap: 2rem;

    .custom-card-container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      height: 375px;
      width: 376px;
      background-color: $blue-200;
      padding: 2rem;

      .card-title {
        text-align: left;
        width: 100%;
        color: $turquoise-100;
        font-size: 48px;
        margin-bottom: 1rem;
      }

      .card-separator {
        height: 1px;
        width: 100%;
        background-color: $gray-500;
      }

      .card-desc {
        margin-top: 4rem;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 1rem;
    border-radius: 16px;

    .our-vision-comment-container {
      .our-vision-text {
        font-size: 12px;
        margin: 0;
        margin-bottom: 1rem;
        margin-top: -1rem;
        padding: 0;
        letter-spacing: 0.5px;
      }
    }
    .our-vision-para {
      width: 100%;
      font-size: 30px;
      z-index: 11;
      padding: 0;

      .first-para {
        b {
          // color: $turquoise-100;
        }
      }
      span {
        color: $turquoise-100;
        font-weight: 700;
      }
    }

    .comment-svg-left {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #288f901a;
      font-size: 64px;
    }
  }
}
