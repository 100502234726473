@import "../../../../DesignSystem/library";

.hear-from-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: 2rem;
  height: 220px;
  border-radius: 20px;
  background-color: white;
  z-index: 10;
  position: relative;

  @media screen and (min-width: 0px) and (max-width: 400px) {
    border-radius: 6px;
    margin-top: 0.5rem;
    height: 110px;
  }
  @media screen and (min-width: 400px) and (max-width: 600px) {
    border-radius: 6px;
    margin-top: 0.5rem;
    height: 100px;
  }
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    height: 200px;
  }

  .hfc-white-layer {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.5rem 2rem;
    width: 100%;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      padding: 0.5rem 0.5rem;
    }

    .hfc-title {
      font-size: 24px;
      color: $turquoise-100;
      font-weight: 300;
      width: 100%;
      z-index: 10;
      background-color: white;
      border-radius: 20px;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 12px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 20px;
      }
    }

    .separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin-bottom: 1.4rem;
      margin-top: 0.5rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        height: 0.28px;
        margin-bottom: 0.6rem;
        margin-top: 0.3rem;
      }
    }

    .hfc-content {
      display: flex;
      gap: 3rem;
      justify-content: end;
      position: relative;
      z-index: 10;
      background-color: white;
      border-radius: 20px;
      width: 100%;
      padding-right: 1rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        padding-right: 0.2rem;
        gap: 0.2rem;
      }

      .hfc-container-1,
      .hfc-container-2,
      .hfc-container-3 {
        font-size: 16px;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 7px;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 10px;
        }

        p {
          margin-bottom: 1rem;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: 0.3rem;
          }
        }
      }
    }
  }
}

.speakers-from-container {
  margin-top: 2rem;
  border-radius: 20px;
  background-color: #ffffff;
  z-index: 10;
  flex: 1;
  position: relative;

  @media screen and (min-width: 0px) and (max-width: 600px) {
    margin-top: 0.5rem;
    border-radius: 6px;
  }

  .sfc-white-layer {
    padding: 1.5rem 2rem;
    width: 100%;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      padding: 0.5rem 0.5rem;
    }

    .sfc-title {
      font-size: 24px;
      color: $turquoise-100;
      font-weight: 300;
      width: 100%;
      z-index: 10;
      background-color: white;
      border-radius: 20px;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 12px;
      }
    }

    .separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin-bottom: 1.4rem;
      margin-top: 0.5rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        height: 0.28px;
        margin-bottom: 0.6rem;
        margin-top: 0.3rem;
      }
    }

    // .sfc-content {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-evenly;
    //   flex-wrap: wrap;
    //   gap: 2rem;
    //   margin-top: -0.4rem;

    //   @media screen and (min-width: 0px) and (max-width: 1200px) {
    //     gap: 0.5rem;
    //     padding-top: 10px;
    //   }

    //   svg {
    //     height: 100px;
    //     width: 12.5%;
    //     @media screen and (min-width: 0px) and (max-width: 600px) {
    //       height: 25px;
    //       width: 12.5%;
    //     }
    //     @media screen and (min-width: 600px) and (max-width: 1200px) {
    //       height: 70px;
    //       width: 12.5%;
    //     }
    //   }
    //   // display: flex;
    //   // flex-direction: row;
    //   // justify-content: flex-start;
    //   // flex-wrap: wrap;
    //   // gap: 35px;
    //   // align-items: center !important;

    //   // .gCFLogo {
    //   //   width: 15%;
    //   //   height: 88px;
    //   // }

    //   // svg {
    //   //   height: 68px;
    //   //   flex: 0 0 calc(calc(100% - 140px) / 5);
    //   // }
    //   // img {
    //   //   flex: 0 0 calc(calc(100% - 140px) / 5);
    //   // }

    //   // @media screen and (max-width: 600px) {
    //   //   display: grid;
    //   //   grid-template-columns: repeat(3, 2fr);
    //   //   gap: 16px;
    //   //   align-items: center;

    //   //   svg {
    //   //     height: 32px;
    //   //     width: 84px;
    //   //   }

    //   //   img {
    //   //     height: 32px;
    //   //     width: 84px;
    //   //   }
    //   // }

    //   // @media screen and (max-width: 390px) {
    //   //   grid-template-columns: repeat(2, 1fr);
    //   // }
    // }
    .sfc-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: center !important;
      justify-content: space-evenly;
      margin-top: -0.4rem;

      svg {
        height: 80px;
        flex: 0 0 calc(calc(100% - 140px) / 7);
      }
      img {
        flex: 0 0 calc(calc(100% - 140px) / 7);
      }

      @media screen and (max-width: 1200px) and (min-width: 600px) {
        svg {
          flex: 0 0 calc(calc(100% - 140px) / 5);
        }
        img {
          flex: 0 0 calc(calc(100% - 140px) / 5);
        }
      }

      @media screen and (max-width: 600px) {
        justify-content: space-around;
        gap: 16px;

        svg {
          height: 32px;
          width: 84px;
          flex: 0 0 calc(33.33% - 16px);
        }

        img {
          height: 32px;
          width: 84px;
          flex: 0 0 calc(33.33% - 16px);
        }
      }
    }
  }
}
