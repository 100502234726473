@import "../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  padding: 2rem;
  padding-top: 1.5rem;
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 1rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }
}

.header {
  position: relative;

  .header-img {
    width: 100%;
    height: 609px;
    object-fit: cover;
    border-radius: 20px;
    filter: brightness(0.8) contrast(1.1);
    margin-bottom: -26px;

    @media (max-width: 1200px) {
      height: 450px;
    }
    @media (max-width: 480px) {
      height: 50vh;
      border-radius: 16px;
      margin-bottom: 0rem;
    }

    @media (max-width: 473px) {
      height: 300px;
      border-radius: 16px;
    }
    @media (max-width: 425px) {
      height: 430px;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 96px;
    font-weight: 300;

    @media (max-width: 1200px) {
      font-size: 96px;
    }

    @media (max-width: 768px) {
      font-size: 64px;
    }

    @media (max-width: 480px) {
      font-size: 48px;
    }
  }
}

.title {
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: $turquoise-100;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #cdcdcd;
  margin: 1.5rem 0;
}

.strategy {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;

  .strategy-img {
    width: 100%;
    height: 520px;
    margin: 1rem auto 2rem auto;

    @media (max-width: 1200px) {
      height: 400px;
    }

    @media (max-width: 768px) {
      height: 250px;
    }
  }
}

.who-we-work-container {
  .title {
    font-size: 48px;
    font-weight: 500;
    color: $turquoise-100;
    // text-align: center;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;
  }

  .cards-container {
    display: flex;
    // justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 1.5rem;
    margin-top: 2.5rem;

    .card {
      flex: 1;
      max-width: 400px;
      height: 200px;
      border-radius: 25px;
      background-color: $turquoise-100;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      box-sizing: border-box;
      text-align: center;

      .img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 20px;
        right: 20px;

        @media (max-width: 1200px) {
          width: 50px;
          height: 50px;
        }

        @media (max-width: 768px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 480px) {
          width: 30px;
          height: 30px;
        }
      }

      .title {
        font-size: 28px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        bottom: 20px;
        left: 20px;
        text-align: left;
        margin: 0;

        @media (max-width: 1200px) {
          font-size: 18px;
          bottom: 15px;
          left: 15px;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          bottom: 10px;
          left: 10px;
        }

        @media (max-width: 480px) {
          font-size: 14px;
          bottom: 5px;
          left: 5px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .cards-container {
      flex-wrap: wrap;
      justify-content: center;
    }

    .card {
      width: calc(33.333% - 1rem);
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .cards-container {
      flex-wrap: wrap;
    }

    .card {
      width: calc(50% - 1rem);
    }
  }

  @media (max-width: 480px) {
    .cards-container {
      flex-wrap: wrap;
    }

    .card {
      width: 100%;
    }
  }
}

.solutions {
  border-radius: 30px;
  background-color: #fff;
  padding: 3rem;
  margin-bottom: 1.5rem;

  .sectors-container {
    display: flex;
    gap: 2rem;
    padding-top: 1rem;

    .sector {
      position: relative;
      flex: 1;
      aspect-ratio: 1 / 1.25;

      .title {
        font-size: 52px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        left: 40px;
        bottom: 30px;

        @media (max-width: 1200px) {
          font-size: 42px;
        }

        @media (max-width: 980px) {
          font-size: 36px;
          left: 28px;
          bottom: 25px;
        }

        @media (max-width: 880px) {
          font-size: 32px;
          line-height: 36px;
        }

        @media (max-width: 768px) {
          font-size: 20px;
          left: 20px;
          bottom: 20px;
        }
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
        filter: brightness(0.8) contrast(1.1);
      }

      .arrow {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        path {
          fill: #fff;
        }

        @media (max-width: 768px) {
          top: 15px;
          right: 15px;
        }
      }
    }
  }
}
