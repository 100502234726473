@import "../../../DesignSystem/library";

$wrapper-margin-top: 2rem;
$wrapper-max-width: 1536px;
$item-width: 200px;
$item-height: 100px;
$animation-duration: 45s;
$items-in-row: 11;

.our-association-container {
  padding: 3rem;
  margin-top: 3rem;
  border-radius: 30px;
  font-weight: 500;
  background-color: #fff;
  font-family: $font-primary;

  @media (max-width: 768px) {
    margin-top: 1rem;
    border-radius: 20px;
    padding: 2rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
    border-radius: 20px;
    padding: 1.5rem;
    border-radius: 16px;
  }

  .oac-title {
    font-size: 48px;
    font-weight: 500;
    border-bottom: 1px solid #a7a7a7;
    padding-bottom: 1.5rem;
    color: $turquoise-100;

    @media (max-width: 768px) {
      font-size: 28px;
      padding-bottom: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 20px;
      padding-bottom: 0.5rem;
      border-bottom: 0.5px solid #a7a7a7;
      font-weight: 300;
    }
  }

  .oac-elements-container {
    margin-top: 1rem;

    .oac-element-representor {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .ele-img-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        width: 600px;
        gap: 1rem;

        img {
          object-fit: contain;
          // width: 50%;
          width: 180px;
          height: 90px;
        }
      }
    }

    .oac-element-representor-expanded {
      // height: 100px;

      .ele-title {
        font-weight: bold;
      }
    }

    .oac-elements-separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin: 1rem 0rem;
    }
  }
}

.marquee {
  margin-top: $wrapper-margin-top;
  width: 100%;
  max-width: $wrapper-max-width;
  margin-inline: auto;
  height: $item-height;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );

  &:hover {
    .item-left,
    .item-right {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
    }
  }

  @keyframes scrollLeft {
    to {
      left: -$item-width;
    }
  }

  @keyframes scrollRight {
    to {
      right: -$item-width;
    }
  }
}

.item-left,
.item-right {
  width: $item-width - 15px;
  height: $item-height;
  position: absolute;
  animation-timing-function: linear;
  animation-duration: $animation-duration;
  animation-iteration-count: infinite;
}

.item-left {
  left: max(calc(#{$item-width} * #{$items-in-row}), 100%);
  animation-name: scrollLeft;
}

.item-right {
  right: max(
    calc(#{$item-width} * #{$items-in-row}),
    calc(100% + #{$item-width})
  );
  animation-name: scrollRight;
}

@for $i from 1 through $items-in-row {
  .item-#{$i} {
    animation-delay: calc(
      #{$animation-duration} /
        #{$items-in-row} *
        (#{$items-in-row} - #{$i}) *
        -1
    );
  }
}

@media (max-width: 768px) {
  $wrapper-margin-top: 1rem;
  $item-width: 100px;
  $item-height: 60px;
  $animation-duration: 37s;

  .marquee {
    margin-top: $wrapper-margin-top;
    max-width: unset;
    height: $item-height;
  }

  .item-left,
  .item-right {
    width: $item-width - 5px;
    height: $item-height;
    animation-duration: $animation-duration;
  }

  .item-left {
    left: max(calc(#{$item-width} * #{$items-in-row}), 100%);
    animation-name: scrollLeft;
  }

  .item-right {
    right: max(
      calc(#{$item-width} * #{$items-in-row}),
      calc(100% + #{$item-width})
    );
    animation-name: scrollRight;
  }

  @for $i from 1 through $items-in-row {
    .item-#{$i} {
      animation-delay: calc(
        #{$animation-duration} /
          #{$items-in-row} *
          (#{$items-in-row} - #{$i}) *
          -1
      );
    }
  }
}

@media (max-width: 480px) {
  $wrapper-margin-top: 0.8rem;
  $item-width: 70px;
  $item-height: 40px;
  $animation-duration: 35s;

  .marquee {
    margin-top: $wrapper-margin-top;
    max-width: unset;
    height: $item-height;
    mask-image: unset;
  }

  .item-left,
  .item-right {
    width: $item-width;
    height: $item-height;
    animation-duration: $animation-duration;
  }

  .item-left {
    left: max(calc(#{$item-width} * #{$items-in-row}), 100%);
    animation-name: scrollLeft;
  }

  .item-right {
    right: max(
      calc(#{$item-width} * #{$items-in-row}),
      calc(100% + #{$item-width})
    );
    animation-name: scrollRight;
  }

  @for $i from 1 through $items-in-row {
    .item-#{$i} {
      animation-delay: calc(
        #{$animation-duration} /
          #{$items-in-row} *
          (#{$items-in-row} - #{$i}) *
          -1
      );
    }
  }
}
