@import "../../DesignSystem/library";

.card-container {
  background-color: white;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color linear 0.2s;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    border-radius: 6px;
  }
}
