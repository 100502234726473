@import "../../../DesignSystem/library";

.container {
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;
  color: #111111;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.8rem 1rem 1.2rem 1rem;
  }

  .white-card {
    border-radius: 30px;
    background-color: #fff;
    padding: 1.5rem 2rem;

    @media (max-width: 768px) {
      border-radius: 20px;
      background-color: #fff;
      padding: 2rem;
    }

    @media (max-width: 480px) {
      border-radius: 20px;
      background-color: #fff;
      padding: 1.5rem;
    }

    .heading {
      font-size: 32px;
      line-height: 38px;
      font-weight: 300;
      margin-bottom: 45px;
      letter-spacing: 0.5px;

      @media (max-width: 1000px) {
        font-size: 28px;
        line-height: 34px;
      }

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 35px;
        letter-spacing: 0.3px;
      }

      @media (max-width: 480px) {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 25px;
        letter-spacing: 0.3px;
      }
    }

    .sucofindo-img {
      float: right;
      width: 50%;
      max-width: 800px;
      border-radius: 30px;
      margin: 0 0 30px 50px;

      @media (max-width: 1000px) {
        width: 48%;
        margin: 0 0 15px 35px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .sucofindo-img-mid {
      display: none;

      @media (max-width: 768px) {
        display: block;
        margin: 0 auto 20px auto;
        width: 90%;
      }

      @media (max-width: 480px) {
        width: 100%;
        margin: 0 auto 15px auto;
      }
    }

    .title,
    .text,
    .italic {
      font-size: 18px;
      line-height: 28px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }

      @media (max-width: 480px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .title {
      margin-top: 25px;
      font-weight: 700;

      @media (max-width: 768px) {
        margin-top: 20px;
      }

      @media (max-width: 480px) {
        margin-top: 15px;
      }
    }

    .italic {
      font-style: italic;
      font-weight: 300;
    }

    .text {
      font-weight: 500;

      span {
        color: $turquoise-100;
        font-weight: 700;
      }

      a {
        color: $turquoise-100;
      }
    }

    .apply-margin {
      margin-top: 25px;
    }

    .strategy-img-container {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;

      .strategy-img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }

      @media screen and (min-width: 0px) and (max-width: 600px) {
        width: 90%;
        margin-top: 1rem;
      }

      @media screen and (min-width: 600px) and (max-width: 1200px) {
        width: 75%;
        margin-top: 1.5rem;
      }
    }

    .outcome {
      margin-top: 16px;

      @media (max-width: 768px) {
        margin-top: 14px;
      }

      @media (max-width: 480px) {
        margin-top: 12px;
      }
    }
  }
}
