@import "../../../DesignSystem/library";

.global-presence-container {
  height: 125vh;
  margin-top: 2rem;
  font-family: $font-primary;

  text {
    font-family: $font-primary;
  }

  @media screen and (max-width: 550px) {
    min-height: 50vh !important;
    max-height: 65vh !important;
  }

  .curved-white-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    height: 100%;
    width: 100%;

    .map-container {
      width: 100%;
      height: 660px;
      overflow: hidden;
      margin-top: 6rem;
    }

    .gp-content-container {
      width: 90%;
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 4rem;

      .gp-content-heading {
        flex: 1;

        .gp-text-our {
          font-weight: 400;
          font-size: 48px;
        }

        .gp-text-global-presence {
          font-size: 64px;
          font-weight: 500;
          color: #288f90;
        }

        .gp-country-container {
          display: flex;
          gap: 1rem;
          .gp-country-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 58px;
            height: 58px;
            border-radius: 50%;
            background-color: #dbdbdb;
            margin-top: 1rem;
          }

          @media screen and (max-width: 550px) {
            gap: 0.5rem;

            .gp-country-circle {
              margin-top: 0.5rem;
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      .gp-partitioner-container {
        display: flex;
        align-items: end;
        height: 100%;

        .gp-partitioner {
          height: 80%;
          width: 1px;
          background-color: #525252;
          margin: 0rem 5rem;
          margin-right: 9rem;
          margin-bottom: 1.5rem;
        }
      }

      .gp-content-para {
        display: flex;
        align-items: end;
        flex: 1;
        height: 100%;
        font-size: 20px;
        letter-spacing: 0.6px;

        p {
          height: fit-content;
          color: #111111;
          line-height: 30px;
          margin-bottom: 2rem;
          width: 100%;
          font-weight: 300;
        }

        @media screen and (max-width: 550px) {
          p {
            line-height: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .map-svg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90vw;
      height: fit-content;

      svg {
        width: 95vw;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 750px) and (max-width: 1100px) {
    min-height: 65vh;
    max-height: 80vh;
    padding: 0 2rem;

    .curved-white-container {
      margin-top: 0rem;

      .map-svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90vw;
        height: fit-content;

        svg {
          width: 95vw;
          height: 100%;
        }
      }

      .gp-content-container {
        .gp-content-heading {
          .gp-text-our {
            font-size: 18px;
          }
          .gp-text-global-presence {
            font-size: 34px;
          }
          .gp-country-container {
            .gp-country-circle {
              width: 38px;
              height: 38px;
            }
          }
        }

        .gp-partitioner-container {
          .gp-partitioner {
          }
        }

        .gp-content-para {
          align-items: start;
          p {
            line-height: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    min-height: 60vh;
    max-height: 75vh;
    padding: 0;
    margin-top: 1rem;

    .curved-white-container {
      margin-top: 0rem;
      justify-content: start;
      border-radius: 16px;

      .map-svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90vw;
        height: fit-content;

        svg {
          width: 95vw;
          height: 100%;
        }
      }

      .map-container {
        width: 100vw;
        height: 300px;
        margin-top: 1rem;
      }
      .gp-content-container {
        flex-direction: column;
        padding-bottom: 0rem;
        height: 30%;
        justify-content: start;
        align-items: start;
        // margin-top: -5rem;
        .gp-content-heading {
          .gp-text-our {
            font-size: 18px;
          }
          .gp-text-global-presence {
            font-size: 34px;
          }
          .gp-country-container {
            .gp-country-circle {
              width: 38px;
              height: 38px;
            }
          }
        }

        .gp-partitioner-container {
          width: 100%;

          .gp-partitioner {
            height: 0.5px;
            width: 100%;
            background-color: black;
            margin: 1.5rem 0rem;
          }
        }

        .gp-content-para {
          font-size: 12px;
          align-items: start;
          line-height: 10px;
          p {
            line-height: 20px;
          }
        }
      }
    }
  }
}

/* Example CSS for Tooltip */
.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  pointer-events: none;
  font-size: 12px;
}
