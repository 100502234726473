@import "../../../DesignSystem/library";

.solution-container {
  background-color: white;
  border-radius: 30px;
  padding: 3rem;
  font-family: $font-primary;
  display: flex;
  flex-direction: column;
  @media (max-width: 760px) {
    padding: 1.5rem;
    border-radius: 30px;
  }
  @media (max-width: 480px) {
    padding: 1rem;
    border-radius: 16px;
  }

  .solution-title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: $turquoise-100;

    @media (max-width: 1200px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (max-width: 768px) {
      font-size: 26px;
      font-weight: 300;
      line-height: 36px;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 1.5rem 0;
    @media (max-width: 768px) {
      margin: 0.3rem 0;
    }
  }

  .solutions-desc {
    font-family: $font-primary;
    color: $black-300;
    margin: 3rem 0rem;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 0.07rem;
    font-weight: 300;
    width: 60%;
    margin-left: auto;

    .desc-header {
      margin-bottom: 20px;
      float: right;

      @media (max-width: 480px) {
        margin-bottom: 5px;
      }
      @media (max-width: 760px) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 1200px) {
      font-size: 16px;
      width: 60%;
      letter-spacing: 0.05rem;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      margin: 1.5rem 0rem;
      width: 100%;
      font-size: 12px;
      letter-spacing: 0.05rem;
      line-height: 20px;
    }
    @media (max-width: 480px) {
      margin: 0.25rem 0rem;
      font-size: 10px;
      letter-spacing: 0.03rem;
      line-height: 16px;
    }
  }

  .img-conatiner {
    position: relative;
    cursor: pointer;

    .header-img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 38px;
      filter: brightness(0.8) contrast(1.1);

      @media (max-width: 1200px) {
        height: 300px;
      }

      @media (max-width: 760px) {
        height: 200px;
        border-radius: 20px;
      }

      @media (max-width: 473px) {
        height: 150px;
        border-radius: 8px;
      }
    }

    .title-img {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 1200px) {
        height: 150px;
        width: 200px;
      }
      @media (max-width: 760px) {
        height: 100px;
        width: 150px;
      }

      @media (max-width: 480px) {
        height: 50px;
        width: 100px;
      }
    }

    .arrow {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      path {
        fill: #fff;
      }

      @media (max-width: 768px) {
        top: 15px;
        right: 15px;
        width: 13px;
        height: 13px;
      }
      @media (max-width: 468px) {
        width: 10px;
        height: 10px;
      }
    }

    .image-sub-text {
      position: absolute;
      bottom: 17%;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-size: 40px;
      text-wrap: nowrap;

      @media screen and (min-width: 200px) and (max-width: 350px) {
        font-size: 14px;
        bottom: 20%;
      }
      @media screen and (min-width: 350px) and (max-width: 480px) {
        font-size: 16px;
        bottom: 20%;
      }
      @media screen and (min-width: 480px) and (max-width: 600px) {
        font-size: 24px;
        bottom: 20%;
      }

      @media screen and (min-width: 600px) and (max-width: 850px) {
        font-size: 30px;
      }
    }
  }
}
