@import "../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 0.8rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }

  .header {
    position: relative;

    .header-img {
      width: 100%;
      height: 609px;
      object-fit: cover;
      border-radius: 30px;
      filter: brightness(0.5);

      @media (max-width: 768px) {
        border-radius: 20px;
        height: 60vh;
      }

      @media (max-width: 480px) {
        border-radius: 20px;
        height: 50vh;
      }
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 128px;
      font-weight: 300;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 64px;
      }

      @media (max-width: 480px) {
        font-size: 42px;
      }
    }
  }

  .releases-container {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 3rem;

    .releases-title {
      font-size: 48px;
      color: $turquoise-100;
      font-weight: 300;
    }

    .separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin: 1rem 0rem;
    }

    .releases {
      .ele-separator-container {
        .ele-container {
          display: flex;
          gap: 5rem;
          justify-content: flex-start;
          padding: 1rem 0rem;
          cursor: pointer;

          .date {
            font-size: 22px;
            letter-spacing: 0.5px;
            white-space: nowrap;
            width: 15%;
            max-width: 240px;
            flex: 1;

            @media (max-width: 850px) {
              min-width: 20%;
            }
          }

          .release-img {
            min-width: 300px;
            width: 398px;
            height: 265px;
            border-radius: 12px;
            flex: 3;

            img {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              object-fit: cover;
            }
          }

          .info {
            max-width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 4;

            .info-date {
              display: none;
            }

            .release-title {
              color: $black-300;
              font-size: 22px;
            }

            .desc {
              font-size: 20px;
              font-weight: 300;
              color: $gray-600;
              margin-top: 0.6rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem;

    .releases-container {
      border-radius: 10px;
      padding: 1.5rem;

      .releases-title {
        font-size: 28px;
        font-weight: 300;
      }

      .separator {
        height: 1px;
        margin: 1rem 0rem;
      }

      .releases {
        .ele-separator-container {
          .ele-container {
            gap: 1.5rem;
            padding: 0.8rem 0 0.3rem 0;

            .date {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.5px;
              white-space: nowrap;
            }

            .release-img {
              flex-grow: 1;
              min-width: 110px;
              max-height: 140px;
              border-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
              }
            }

            .info {
              .release-title {
                font-size: 12px;
                line-height: 14px;
              }

              .desc {
                font-size: 10px;
                line-height: 12px;
                width: unset;
                margin-top: 0.4rem;
              }

              .link-container {
                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    .releases-container {
      border-radius: 10px;
      padding: 1.5rem;

      .releases-title {
        font-size: 14px;
        font-weight: 300;
      }

      .separator {
        height: 0.5px;
        margin: 0.5rem 0rem;
      }

      .releases {
        .ele-separator-container {
          .ele-container {
            gap: 1.2rem;
            padding: 0.8rem 0 0.3rem 0;

            .date {
              display: none;
            }

            .release-img {
              width: 45%;
              min-width: 45%;
              max-height: 90px;
              border-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
              }
            }

            .info {
              max-width: unset;
              .info-date {
                display: unset;
                font-size: 10px;
                line-height: 11px;
                letter-spacing: 0.5px;
                white-space: nowrap;
              }

              .release-title {
                font-size: 12px;
                line-height: 13px;
              }

              .desc {
                font-size: 8px;
                line-height: 9px;
                width: unset;
                margin-top: 0.4rem;
              }

              .link-container {
                svg {
                  width: 6px;
                  height: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}
