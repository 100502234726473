@import "../../DesignSystem/library";

.container {
  display: flex;
  align-items: stretch;
  gap: 50px;
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }

  .wind-farm-img {
    max-width: 35%;
    height: auto;
    border-radius: 20px;
    object-fit: cover;

    @media (max-width: 480px) {
      display: none;
    }
  }

  .right-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    padding: 3rem;
    flex-grow: 1;

    @media (max-width: 480px) {
      border-radius: 16px;
      width: 100%;
      padding: 1.5rem;
    }

    .details {
      font-weight: 500;
      color: #111111;
      font-size: 52px;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid #000000;

      @media (max-width: 768px) {
        font-size: 26px;
        padding-bottom: 1.3rem;
      }

      @media (max-width: 480px) {
        letter-spacing: 0.5px;
        font-size: 20px;
        padding-bottom: 1rem;
        border-bottom: 0.5px solid #000000;
      }

      p {
        line-height: 56px;
        max-width: 19ch;
        font-weight: 300;

        @media (max-width: 768px) {
          line-height: 32px;
        }

        @media (max-width: 480px) {
          line-height: 26px;
          max-width: 20ch;
        }
      }

      span {
        font-weight: 600;
        color: $turquoise-100;
      }
    }

    .btn {
      margin-top: 4rem;
      margin-left: auto;
      padding: 1rem 2rem;
      background-color: #111111;
      font-size: 20px;

      @media (max-width: 768px) {
        font-size: 14px;
        padding: 0.6rem 1.2rem;
        border-radius: 3px;
        margin-top: 3.6rem;
      }

      @media (max-width: 480px) {
        font-size: 10px;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        margin-top: 3rem;
      }
    }
  }
}
