@import "../../DesignSystem/library";

.tab-container {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
  font-family: $font-primary;
  @media screen and (min-width: 0px) and (max-width: 600px) {
    gap: 0.5rem;
    justify-content: end;
  }
}

.tab-item {
  cursor: pointer;
  color: $turquoise-100;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 5px;
  white-space: nowrap;
  @media screen and (min-width: 0px) and (max-width: 600px) {
    font-size: 8px;
    padding-bottom: 0px;
  }
  &.active {
    border-bottom: 2px solid $turquoise-100;
    font-weight: 600;
  }
}
