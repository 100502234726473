@import "../../../DesignSystem/library";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 95px;
  background-color: $blue-300;
  padding: 0 2rem;
  font-family: $font-primary;
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #dfe1e6;

  @media (max-width: 900px) {
    box-shadow: unset;
  }

  .se-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .se-logo-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: $font-quinary;
      margin-top: 0.8rem;

      .main-text {
        font-size: 8.92px;
        font-weight: 600;
      }

      .secondary-text {
        font-size: 6.25px;
        font-weight: 400;
        letter-spacing: 0.2px;
      }
    }
  }

  .header-right-container {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 1100px) {
      gap: 30px;
    }
  }

  .header-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-family: $font-primary;
    font-size: 15px;
    font-weight: 300;

    .dropdown-container {
      border-radius: 9px;
    }

    p {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      letter-spacing: 0.5px;
      cursor: pointer;

      &.active {
        // font-weight: 600;
        color: $turquoise-100;
      }

      .arrow-container {
        margin-bottom: 0.1rem;
      }
    }

    @media (max-width: 1100px) {
      gap: 20px;
    }
  }

  .header-btns-container {
    display: flex;
    align-items: center;
    justify-content: end;
    font-family: $font-primary;
    gap: 15px;
  }

  @media screen and (max-width: 900px) {
    height: 61px;
    align-items: center;
    padding: 0rem 2rem;
    justify-content: space-between;
    background-color: $blue-300;
    position: relative;
    border-bottom: 1px solid #dfe1e6;

    .bread-crumb-container {
      cursor: pointer;

      .close-icon-container {
        svg {
          width: 14px;
          height: 16px;
        }
      }
    }

    .se-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25.88px;

      svg {
        width: 200px;
        path {
        }
      }
    }

    .clem-btn-container {
      height: 19.97px;
    }

    .header-popup-container {
      position: absolute;
      width: 100vw;
      height: calc(100vh - 105px);
      top: calc(100%);
      right: 0%;
      z-index: 111;
      background-color: #ffffff;
      padding: 1rem 1.2rem;
      box-shadow: 5px 4px 9.2px 0px #a4a4a440;

      @media (max-width: 500px) {
        width: 100vw;
      }

      .logo-exit-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .se-logo-container-popup {
          width: 60.83px;
          height: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .hp-links-container {
        margin-top: 1.5rem;

        .separator {
          height: 0.5px;
          width: 100%;
          background-color: $gray-900;
          z-index: 10;
          margin-top: 0.7rem;
        }

        .hp-link-element {
          margin-top: 1rem;
          position: relative;

          .solutions-element {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .solutions-dropdown-container {
            // border: 1px solid red;
            margin-top: 1rem;
            color: black;
            font-weight: 400;
            background-color: #e8e6e6;
            padding: 0.7rem 0.8rem;
            border-radius: 6px;

            .clem-dropdown-wrapper {
              margin-top: 0.6rem;

              .clem-element {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }

              .clem-dropdown-container {
                padding: 0.8rem;
                background-color: #f2f7fe;
                margin-top: 0.4rem;
                border-radius: 7px;
                font-size: 14px;

                .clem-dropdown-ce-element {
                  margin-top: 0.6rem;
                }
              }
            }
          }

          &.active {
            font-weight: 600;
            color: $turquoise-100;
          }

          .mobile-dropdown {
            position: unset;
            margin-top: 8px;
            background-color: unset;
            box-shadow: unset;
            width: 100%;
          }

          .dropdown-item-mobile {
            color: #000;
            font-size: 18px;
            font-weight: 500;
          }

          .dropdown-mobile-seperator {
            margin: 10px 0;
          }
        }
      }

      .clem-btn-container {
        margin: 1.5rem 0rem;
      }

      .get-in-touch-container {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
      }
    }

    .visible {
      animation: slideIn 0.5s;
      right: 0%;
    }

    .hide {
      right: 0%;
      animation: slideOut 0.5s;
    }

    @keyframes slideIn {
      0% {
        right: -50%;
      }

      100% {
        right: 0%;
      }
    }

    @keyframes slideOut {
      0% {
        right: 0%;
      }
      // 50% {
      //   right: -50%;
      // }
      100% {
        right: -100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 1.5rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0rem 1rem;
  }

  .banner-wrapper {
    position: relative;
    width: 100%;
    z-index: 1111;
  }
}
