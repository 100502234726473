@import "../../../../DesignSystem/library";

.event-info-container {
  margin-top: 3rem;
  border-radius: 10px;
  background-color: white;
  font-family: $font-primary;
  padding: 3rem;
  @media screen and (min-width: 0px) and (max-width: 600px) {
    padding: 1.5rem;
    font-size: 14px;
    margin-top: 1.5rem;
  }
  .event-title {
    font-size: 48px;
    color: $turquoise-100;
    font-weight: 300;
    width: 100%;
    z-index: 10;
    background-color: white;
    border-radius: 20px;
    @media screen and (min-width: 0px) and (max-width: 600px) {
      font-size: 14px;
    }
    @media screen and (min-width: 600px) and (max-width: 1200px) {
      font-size: 32px;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $gray-500;
    margin-bottom: 1.4rem;
    margin-top: 0.7rem;
    position: relative;
    @media screen and (min-width: 0px) and (max-width: 600px) {
      height: 0.28px;
      margin-bottom: 0.6rem;
      margin-top: 0.3rem;
    }
    .tabbed-component-container {
      position: absolute;
      width: 200px;
      right: 2.6rem;
      bottom: 1.2rem;
      z-index: 11;
      height: 10px;
      @media screen and (min-width: 0px) and (max-width: 600px) {
        right: 0rem;
        height: 0px;
        bottom: 0.7rem;
      }
    }
  }
}
