@import "../../DesignSystem/library";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-100;
  color: #ffffff;
  padding: 0.6rem 1rem;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  font-family: $font-primary;
  letter-spacing: 1px;
  white-space: nowrap;
  position: relative;
  @media screen and (min-width: 0px) and (max-width: 600px) {
    padding: 2.8px 5.7px;
  }
}

.btn-shiny::before {
  content: "";
  position: absolute;
  width: 78px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.511),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 2s infinite linear;
}

@keyframes shine {
  0% {
    left: -50%;
  }
  20% {
    left: 0%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}
