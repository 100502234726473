@import "../../../DesignSystem/library";

.footer-container {
  background-color: $black-200;
  font-family: $font-primary;
  color: white;
  padding: 2rem 3rem;

  .clem-content-wrapper {
    display: flex;
    align-items: start;
    .clem-email-sm-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1.2;
      height: 100%;
      position: relative;
      gap: 6rem;

      .clem-logo {
        font-family: $font-quinary;
        font-weight: 500;
        font-size: 40px;
        margin-bottom: 2rem;
      }
      .clem-desc {
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 1px;

        @media (max-width: 480px) {
          font-size: 14px !important;
        }
      }
      .email-input-container {
        display: flex;
        margin: 0.8rem 0rem;
        height: 38px;

        input {
          width: 80%;
          min-width: 200px;
          border-radius: 4px;
          margin-right: 0.2rem;
          border: none;
          outline: none;
          padding-left: 1rem;
          font-family: $font-primary;
          letter-spacing: 1px;
        }
      }
      .social-media-links-container {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-top: 1.5rem;

        div {
          cursor: pointer;
        }
      }
    }

    .right-container {
      flex: 2;
      padding-left: 8rem;

      .spcr-container {
        display: flex;
        justify-content: start;

        .solutions-container,
        .platforms-container,
        .company-container,
        .resources-container {
          font-size: 18px;
          letter-spacing: 1px;
          margin-right: 2.4rem;

          .heading {
            font-weight: 700;
            margin-bottom: 2rem;
          }

          .child-container {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
            p {
              font-size: 16px;
              font-weight: 300;
              cursor: pointer;
            }
          }
        }

        .platforms-container {
          .child-container {
            p {
              color: #535353;
              cursor: not-allowed;
            }
          }
        }
      }

      .address-container {
        display: flex;
        gap: 1.6rem;
        margin: 3.5rem 0 2.5rem 0;

        .inner-container {
          flex: 1;
          .title {
            font-weight: 700;
            margin-bottom: 0.6rem;
          }

          .address-details {
            font-size: 14px;
            font-weight: 300;
            cursor: pointer;
            letter-spacing: 0.5px;
            line-height: 20px;

            span {
              text-wrap: nowrap;
            }
          }
        }
      }
    }
  }
  .tps-copyrights-container {
    display: flex;
    justify-content: space-between;

    .copyrights {
      font-size: 16px;
      font-weight: 300;
      flex: 1;
    }

    .tps-disabled {
      color: #535353;
      cursor: not-allowed;
    }

    .tps-container {
      display: flex;
      gap: 1.6rem;
      bottom: 2rem;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
      letter-spacing: 1px;
      flex: 1.3;
    }
  }

  @media (max-width: 980px) {
    background-color: #0e0e0e;
    flex-direction: column;
    justify-content: start;
    height: fit-content;
    padding: 2rem;

    .clem-email-sm-container {
      margin-top: 1rem;
      .clem-logo {
        height: 41.76px;
        width: 100px;
      }
      .clem-desc {
        font-size: 14px;
        margin-top: 1rem;
      }

      .email-input-container {
        display: flex;
        margin: 0.8rem 0rem;
        height: 38px;

        input {
          width: 80%;
          min-width: 200px;
          border-radius: 4px;
          margin-right: 0.2rem;
          border: none;
          outline: none;
          padding-left: 1rem;
          font-family: $font-primary;
          letter-spacing: 1px;
        }
      }
    }

    .spcr-container-mobile {
      position: relative;
      padding-left: 0rem;
      flex-direction: column;

      .solutions-platform-container-mobile {
        display: flex;
        gap: 4rem;
        margin-top: 1.2rem;
      }

      .address-container-mobile {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        margin-top: 1rem;

        .inner-container {
          .title {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0.5rem;
          }

          .address-details {
            font-size: 14px;
            font-weight: 300;
            cursor: pointer;
            letter-spacing: 0.5px;
            line-height: 20px;

            span {
              text-wrap: nowrap;
            }
          }
        }
      }

      .cr-wrapper,
      .cr-wrapper-mobile {
        display: flex;
        gap: 4rem;
        margin-top: 2.5rem;
      }

      .cr-wrapper-mobile {
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
      }

      .solutions-container-mobile,
      .platforms-container-mobile,
      .company-container-mobile,
      .resources-container-mobile,
      .tps-container-mobile {
        .heading-mobile {
          font-weight: 700;
          margin-bottom: 1rem;
          font-size: 18px;
        }

        .child-container-mobile {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          p {
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
            color: $gray-1000;
          }
        }

        .child-container-mobile-platform {
          p {
            color: #535353;
          }
        }
      }
    }

    .tps-container-mobile {
      margin-top: 1.5rem;

      .child-container-mobile {
        display: flex;
        gap: 1rem;
      }

      .tps-disabled {
        color: #535353 !important;
        cursor: not-allowed;
      }
    }
  }
}
