// // make scrollable and hide scrollbar
.hide-scrollbar {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}
