@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,500;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayMediu.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayLight.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayRoman.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayThin.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayXThin.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Neue-Haas";
  src: url("../assets/font/neue-haas-grotesk/NeueHaasDisplayXXThin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

// $font-primary: Manrope, sans-serif;
$font-primary: Archivo, "sans-serif";
$font-secondary: IBM Plex Sans, "sans-serif";
$font-tertiary: Archivo, "sans-serif";
$font-quaternary: Inter, sans-serif;
$font-quinary: Poppins, "sans-serif";
