@import "../../../DesignSystem/library";
.clem-ecosystem {
    background-color: white;
    border-radius: 30px;
    padding: 3rem;
    font-family: $font-primary;
    display: flex;
    flex-direction: column;
    @media (max-width: 760px) {
        padding: 1.5rem;
        border-radius: 20px;
    }
    @media (max-width: 480px) {
        padding: 1rem;
        border-radius: 16px;
    }
    .ecosystem-title {
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        color: $turquoise-100;

        @media (max-width: 1200px) {
            font-size: 36px;
            line-height: 44px;
        }

        @media (max-width: 768px) {
            font-size: 26px;
            font-weight: 300;
            line-height: 36px;
        }
    }

    .separator {
        width: 100%;
        height: 1px;
        background-color: #cdcdcd;
        margin: 1.5rem 0;
        @media (max-width: 768px) {
            margin: 0.3rem 0;
        }
    }
    .img-container{
        margin: 20px;
        @media (max-width: 760px) {
            margin: 10px;
        } 
        @media (max-width: 480px) {
            margin: 5px;
            padding-top: 5px;
        }
    }
}