@import "../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 0.8rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }
}

.header {
  position: relative;

  .header-img {
    width: 100%;
    height: 609px;
    object-fit: cover;
    border-radius: 30px;
    filter: brightness(0.8) contrast(1.1);

    @media (max-width: 768px) {
      border-radius: 20px;
      height: 60vh;
    }

    @media (max-width: 480px) {
      border-radius: 20px;
      height: 50vh;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 128px;
    font-weight: 300;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 64px;
    }

    @media (max-width: 480px) {
      font-size: 42px;
    }
  }
}
