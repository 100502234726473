.container {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 800px) {
    width: 95%;
  }

  svg {
    width: 100%;
    height: auto;
    transition: all 0.4s ease
  }

  .popup {
    background-color: #eaf3ff;
    border-radius: 15px;
    box-shadow: 0px 4px 10px 0px #47474740;
    padding: 15px 25px;
    width: 300px;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease, transform 0.3s ease;

    @media (max-width: 1200px) {
      width: 260px;
    }

    @media (max-width: 800px) {
      width: 200px;
    }

    .title {
      color: #111111;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 24px;

      @media (max-width: 800px) {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 16px;
      }
    }

    .desc {
      color: #000000;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.5px;

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .visible {
    opacity: 1;
    transform: translateY(0);
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }
  .playPauseButton{
    position: absolute;
    top:10px;
    right: 0px;
    cursor: pointer;
  }
}
