@import "../../../DesignSystem/library";

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  padding-top: 1.5rem;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );
  font-family: $font-primary;

  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 0.8rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }

  .interview-container {
    border-radius: 30px;
    background-color: #fff;
    padding: 3rem;

    @media (max-width: 768px) {
      border-radius: 20px;
      background-color: #fff;
      padding: 2rem;
    }

    @media (max-width: 480px) {
      border-radius: 20px;
      background-color: #fff;
      padding: 1.5rem;
    }

    .title {
      font-size: 48px;
      font-weight: 500;
      border-bottom: 1px solid #a7a7a7;
      padding-bottom: 1.5rem;
      color: $turquoise-100;

      @media (max-width: 768px) {
        font-size: 28px;
        padding-bottom: 1rem;
      }

      @media (max-width: 480px) {
        font-size: 12px;
        padding-bottom: 0.5rem;
        border-bottom: 0.5px solid #a7a7a7;
      }
    }

    .text {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: #111;
      margin-top: 2rem;
      margin-bottom: 3rem;
      width: 65%;

      @media (max-width: 1200px) {
        width: 75%;
      }

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
        margin-top: 1.4rem;
        margin-bottom: 2rem;
        width: 85%;
      }

      @media (max-width: 480px) {
        font-size: 11px;
        line-height: 13px;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }

    .desc-container {
      display: flex;
      justify-content: end;
      width: 100%;
      letter-spacing: 0.5px;

      P {
        font-weight: 300;
        font-size: 18px;
        width: 40%;
        float: right;
        text-align: left;

        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 50%;
          line-height: 16px;
        }
      }
    }

    .iframe {
      border: 0px;
      margin: 0 auto;
      width: 100%;
      aspect-ratio: 3 / 1.3;
    }
  }
}
