@import "../../DesignSystem/library";

.cater-to-container {
  position: relative;
  background-color: #106666;
  border-radius: 30px;
  padding: 3rem;
  font-family: $font-primary;
  min-height: 100vh;

  @media screen and (min-width: 200px) and (max-width: 600px) {
    border-radius: 16px;
  }

  .ctc-title {
    font-size: 48px;
    margin-bottom: 1rem;
    color: #ffffff;
    font-weight: 300;

    @media (max-width: 1200px) {
      font-size: 36px;
    }

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  .ctc-separator {
    height: 1px;
    width: 100%;
    background-color: $gray-500;
    z-index: 10;
  }

  .ctc-desc {
    display: flex;
    justify-content: end;
    width: 100%;
    float: right;
    margin: 4rem 0rem;
    line-height: 24px;
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 6rem;
    letter-spacing: 0.5px;

    p {
      font-weight: 300;
      width: 450px;

      @media (max-width: 1200px) {
        width: 350px;
      }

      @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
      }
    }
  }

  .ctc-cards-container {
    display: flex;
    width: 100%;
    gap: 0.6rem;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }

    .ctc-card {
      z-index: 10;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      transition: flex 0.4s ease-in-out;

      .title-separator-container {
        .ctc-card-title {
          font-size: 24px;
          color: $turquoise-100;
          margin: 1rem 0rem;
          font-weight: 500;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        .ctc-card-separator {
          height: 1px;
          width: 100%;
          background-color: $gray-500;
        }
      }

      .ctc-card-desc {
        display: flex;
        justify-content: end;
        width: 98%;
        float: right;
        margin-bottom: 2rem;
        line-height: 24px;
        font-size: 20px;
        font-weight: 300;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        @media (max-width: 768px) {
          font-size: 16px;
          width: 100%;
        }
      }
    }

    .ctc-card-normal {
      flex: 1;
      transition: flex 0.4s ease-in-out;
    }

    .ctc-card-expanded {
      flex: 3;

      .title-separator-container {
        .ctc-card-title {
          font-weight: 600;
        }
      }

      .ctc-card-desc {
        opacity: 1;
        p {
          width: 420px;
          letter-spacing: 0.5px;

          @media (max-width: 1200px) {
            width: 350px;
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }

  .top-wave-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .bottom-wave-container {
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    z-index: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }
}
