@import "../../../DesignSystem/library";

.link-section-container {
  margin-top: 3rem;
  background-color: white;
  border-radius: 30px;
  padding: 3rem;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  row-gap: 1rem;
  font-family: $font-primary;

  .grid-item {
    background-color: $gray-300;
    height: 415px;
    width: calc(50vw - 6rem);
    border-radius: 30px;
    padding: 2rem;
    position: relative;

    img {
      object-fit: cover;
      border-radius: 25px;
    }

    .card-title {
      font-size: 48px;
      color: $turquoise-100;
      margin-bottom: 2rem;
    }
    .card-separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
    }

    .card-desc {
      font-size: 18px;
      line-height: 24px;
      width: 70%;
      color: $black-100;
      font-weight: 300;
      height: 250px;
      display: flex;
      align-items: end;
    }

    .visit-me-icon-container {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
    }

    transition: all ease-out 0.2s;
  }

  .grid-item:hover {
    background-color: $blue-200;
    box-shadow: 0px 4px 12.7px 0px #36363640;
  }

  .grid-item-img {
    padding: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 950px) {
    .card-desc {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 768px) {
  }
}
