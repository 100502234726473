@import "../../../../../DesignSystem/library";

.mainevent-container {
  font-family: $font-primary;

  .register-now-container {
    position: relative;
    border-radius: 20px;
    height: 274px;
    background-color: $blue-200;
    padding: 2rem 3rem;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      padding: 1rem 1.5rem;
      border-radius: 6px;
      height: 128px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
      height: 204px;
    }

    .rnc-title {
      font-size: 32px;
      font-weight: 400;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 10px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 20px;
      }
    }

    .rnc-desc {
      font-size: 20px;
      font-weight: 300;
      margin-top: 0.5rem;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
        margin-top: 0.2rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 15px;
        margin-top: 0.2rem;
      }
    }

    .rnc-date {
      color: $black-300;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      letter-spacing: 0.5px;
      font-size: 20px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 12px;
        margin-top: 0.8rem;
        margin-bottom: 0.4rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 15px;
        margin-top: 0.2rem;
      }
    }

    .rnc-format {
      color: $turquoise-100;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.5px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 12px;
      }
    }

    .rnc-address {
      color: $turquoise-100;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-top: 0.7rem;
      font-weight: 300;
      width: 270px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 8px;
        margin-top: 0.4rem;
        width: 140px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 0.4rem;
        width: 230px;
      }
    }

    .register-btn-container {
      position: absolute;
      bottom: 2rem;
      right: 2rem;

      @media screen and (min-width: 420px) and (max-width: 600px) {
        bottom: 1rem;
        right: 1rem;
      }
      @media screen and (min-width: 0px) and (max-width: 420px) {
        bottom: 0.5rem;
        right: 1rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        bottom: 1.2rem;
        right: 1rem;
      }

      .register-btn-styles {
        width: 130px;
        height: 40px;
        background-color: black;
        font-size: 16px;
        font-weight: 300;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          width: 55px;
          height: 18px;
          font-size: 7px;
          border-radius: 1.7px;
          letter-spacing: 0.4px;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          width: 80px;
          height: 24px;
          font-size: 10px;
          border-radius: 1.7px;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .agenda-container {
    margin-top: 1.5rem;

    @media screen and (min-width: 0px) and (max-width: 600px) {
      margin-top: 0.5rem;
    }

    .agenda-separator {
      height: 1px;
      width: 100%;
      background-color: $gray-500;
      margin: 1rem 0rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        height: 0.28px;
        margin: 0.5rem 0rem;
      }
    }

    .panelist-container {
      display: flex;
      justify-content: space-between;

      .panelist-child-container {
        display: flex;
        flex-direction: column;
      }

      .svg-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        flex: 1;
        align-items: end;
        justify-content: end;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          gap: 0.5rem;
          margin-left: 1rem;
        }

        .cix-idc-se-container {
          margin-bottom: -1rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: 0rem;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            margin-bottom: 0rem;
          }
        }

        .smbc-container {
          margin-bottom: 1rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: 1.5rem;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            margin-bottom: 1rem;
          }
        }
        .cix-se-logo {
          margin-top: 1rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-top: 0rem;
            margin-bottom: 0.3rem;
          }
        }

        .growald-cfi-container {
          margin-top: -2rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: 0rem;
          }

          .growald-img-container {
            width: 180px;
            height: 60px;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              width: 45px;
              height: 38px;
              margin-right: -0.6rem;
              margin-left: 0.5rem;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              width: 75px;
              height: 38px;
              margin-right: -0.7rem;
            }
          }

          .cfi {
            transform: scale(0.8);
          }
        }

        div {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 2rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            gap: 1rem;
          }

          .aci-logo {
            width: 200px;
            transform: scale(1.5);
            margin-right: 3.2rem;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              width: 40px;
              margin-right: 0rem;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              width: 70px;
              margin-right: 0rem;
            }
          }
          .asix-logo {
            height: 20px;
          }

          .cix-logo {
            transform: scale(1.5);
            margin-right: 2.5rem;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              margin-right: 0rem;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              margin-right: 0rem;
            }
            svg {
              // margin-top: 3rem;
              @media screen and (min-width: 0px) and (max-width: 600px) {
                margin-top: 0rem;
              }
            }
          }

          svg {
            width: 150px;
            height: 70px;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              width: 45px;
              height: 38px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              width: 75px;
              height: 38px;
            }
          }

          .harvard-logo {
            @media screen and (min-width: 0px) and (max-width: 600px) {
              svg {
                width: 55px;
                height: 52px;
              }
            }
          }
        }

        .cli-new-icon-container {
          margin-top: -1rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-top: -1rem;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            margin-bottom: 0rem;
            margin-top: -1rem;
          }
        }
        .se-anthro-icon-container {
          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-bottom: -0.5rem;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            margin-bottom: 0rem;
          }
        }
      }

      .svg-fjt-container {
        margin-top: -1rem;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          margin-top: 0rem;
        }
      }
    }

    .agenda-title {
      font-size: 36px;
      color: $turquoise-100;
      font-weight: 300;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        font-size: 12px;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        font-size: 20px;
      }
    }

    .timeline-info-container {
      .title-time-container {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        .timeline-title {
          font-size: 20px;
          font-weight: 600;
          width: 80%;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 10px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 15px;
            width: 80%;
          }
        }

        .timeline-time {
          font-size: 18px;
          font-weight: 300;
          padding-right: 13px;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 8px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 14px;
          }
        }
      }

      .timeline-desc {
        font-size: 18px;
        color: $black-300;
        margin-top: 0.9rem;
        // border: 1px solid red;
        width: 80%;
        line-height: 24px;
        font-weight: 300;
        letter-spacing: 0.5px;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 8px;
          margin-top: 0.4rem;
          line-height: 10px;
          width: 80%;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 14px;
          margin-top: 0.4rem;
          line-height: 18px;
          width: 80%;
        }
      }
    }

    .blue-strip-container {
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      background-color: $blue-200;
      padding: 1rem;
      margin-top: 20px;
      margin-bottom: 20px;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        padding: 0.3rem 0.5rem;
        border-radius: 3.5px;
      }

      .bs-title {
        color: $turquoise-100;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.5px;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 10px;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 15px;
        }
      }

      .bs-time {
        font-size: 18px;
        font-weight: 300;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 8px;
          // top:1.5rem;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 13px;
        }
      }
    }

    .people-info-contaier {
      margin-top: 1.5rem;

      @media screen and (min-width: 0px) and (max-width: 600px) {
        margin-top: 0.5rem;
      }

      .pi-title {
        font-size: 20px;
        font-weight: 300;
        color: $turquoise-100;
        margin-bottom: 0.7rem;
        letter-spacing: 0.5px;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 9px;
          margin-bottom: 0.3rem;
        }
        @media screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 15px;
        }
      }

      .pi-desc {
        margin-left: 1.5rem;
        letter-spacing: 0.5px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        justify-content: space-between;

        @media screen and (min-width: 0px) and (max-width: 600px) {
          margin-left: 0.5rem;
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          margin-left: 0.5rem;
          grid-template-columns: repeat(4, 1fr);
        }
        .image-container {
          display: flex;
          align-items: center;
          flex-direction: column;
          .type-content {
            font-size: 12px;
            font-weight: 550;
            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 6px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 10px;
            }
          }
          .panelist-name {
            cursor: pointer;
            color: $turquoise-100;
            font-weight: 400;
            font-size: 16px;
            padding-top: 4px;
            padding-bottom: 6px;
            text-align: center;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 12px;
            }
          }
          .description {
            font-size: 12px;
            text-align: center;
            line-height: 15px;

            .sub-description {
              margin-top: 8px;

              @media screen and (min-width: 0px) and (max-width: 600px) {
                margin-top: 4px;
              }
              @media screen and (min-width: 600px) and (max-width: 1000px) {
                margin-top: 6px;
              }
            }

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
              line-height: 10px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 10px;
              line-height: 12px;
            }
          }
          // grid-template-columns: repeat(6, 1fr);
        }

        li {
          margin-top: 0.4rem;
          font-weight: 300;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            margin-top: 0.4rem;
            font-size: 8px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 12px;
          }

          span {
            cursor: pointer;
            color: $turquoise-100;
            font-weight: 400;

            @media screen and (min-width: 0px) and (max-width: 600px) {
              font-size: 8px;
            }
            @media screen and (min-width: 600px) and (max-width: 1000px) {
              font-size: 12px;
            }
          }
        }

        li::marker {
          color: $turquoise-100;
          font-size: 14px;

          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 6px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 10px;
          }
        }
      }
    }

    .insights-container {
      margin-top: 2rem;
      letter-spacing: 0.5px;
      @media screen and (min-width: 0px) and (max-width: 600px) {
        margin-top: 1rem;
      }

      .insights-header {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          align-items: end;
        }
        .insights-title {
          color: $turquoise-100;
          font-size: 24px;
          font-weight: 300;
          margin-top: 0.2rem;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
      .insights-button {
        background-color: #288f90;
        color: white;
        font-size: 16px;
        font-weight: 300;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          font-size: 7px;
          letter-spacing: 0.2px;
          border-radius: 2px;
        }
      }

      .insights-content {
        padding: 1rem;
        background-color: $blue-200;
        border-radius: 20px;
        margin-top: 1rem;
        @media screen and (min-width: 0px) and (max-width: 600px) {
          margin-top: 0.5rem;
          padding: 0.2rem;
          border-radius: 6px;
          padding-bottom: 0.5rem;
        }

        li {
          margin-left: 1.7rem;
          margin-top: 1rem;
          font-size: 20px;
          font-weight: 300;
          width: 80%;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 8px;
            margin-left: 1rem;
            margin-top: 0.5rem;
            width: 80%;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 12px;
            margin-left: 1rem;
            margin-top: 0.5rem;
            width: 80%;
          }
        }

        li::marker {
          font-size: 16px;
          @media screen and (min-width: 0px) and (max-width: 600px) {
            font-size: 5px;
          }
          @media screen and (min-width: 600px) and (max-width: 1000px) {
            font-size: 7px;
          }
        }
      }
    }
  }
}
