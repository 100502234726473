@import "../../../DesignSystem//library";
.solutions {
  border-radius: 16px;
  background-color: #fff;
  padding: 1rem;
  .solutions-title{
    font-size: 26px;
    font-weight: 300;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin-top: 0.5rem;
    margin-bottom: 1.4rem;
  }

  .sectors-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    padding-top: 1rem;

    .sector {
      position: relative;
      flex: 1 1 calc(33.333% - 1rem);
      box-sizing: border-box;

      .title {
        font-size: 60px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        left: 40px;
        bottom: 30px;
        max-width: 80%;
        flex-wrap: wrap;

        @media (max-width: 1200px) {
          font-size: 44px;
        }

        @media (max-width: 992px) {
          font-size: 38px;
        }

        @media (max-width: 768px) {
          font-size: 32px;
          left: 20px;
          bottom: 20px;
        }

        @media (max-width: 576px) {
          font-size: 24px;
          left: 10px;
          bottom: 10px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
          left: 8px;
          bottom: 18px;
        }
        @media (max-width: 430px) {
          font-size: 18px;
          left: 8px;
          bottom: 18px;
        }
        @media (max-width: 414px) {
          font-size: 16px;
          left: 8px;
          bottom: 18px;
        }
        @media (max-width: 375px) {
          font-size: 14px;
          left: 8px;
          bottom: 18px;
        }
        @media (max-width: 360px) {
          font-size: 13px;
          left: 6px;
          bottom: 18px;
        }
      }

      .img {
        width: 100%;
        height: 272px;
        object-fit: cover;
        border-radius: 8px;
        filter: brightness(0.5) contrast(1.1);

        @media (max-width: 1200px) {
          height: 200px;
        }

        @media (max-width: 768px) {
          height: 150px;
        }
      }

      .arrow {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        path {
          fill: #fff;
        }

        @media (max-width: 768px) {
          top: 15px;
          right: 15px;
        }
        @media (max-width: 480px) {
          width: 10px;
          height: 10px;
          top: 15px;
          right: 15px;
        }
      }
    }
  }
}
