@import "../../../DesignSystem/library";

.our-strategy-container {
  margin: 3rem 0;
  padding: 3rem;
  font-family: $font-primary;
  background-color: white;
  border-radius: 30px;

  .os-title-svg-container {
    display: flex;
    margin-bottom: 3rem;

    .os-title-container {
      flex: 1;
      .os-title {
        color: $turquoise-100;
        font-size: 48px;
        font-weight: 500;
      }
      .os-separator {
        width: 100%;
        height: 1px;
        background-color: $gray-500;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      .os-desc {
        color: $black-300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        font-weight: 400;
        width: 90%;
      }
    }

    .os-svg-container {
      display: flex;
      align-self: flex-end;
      padding-left: 5rem;
      width: 65%;

      svg {
        width: 100%;
        height: auto;
      }

      @media (max-width: 1200px) {
        width: 60%;
        padding-left: 3rem;
      }
    }
  }

  .os-cards-container {
    display: flex;
    height: auto;
    min-height: 544px;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;

    .os-card-normal {
      flex: 1;
      transition: flex 0.4s ease-in-out;
    }

    .os-card-expanded {
      flex: 3;

      .card-title {
        width: 300px;
        p {
          font-weight: 600;
        }
      }
    }

    .os-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 291px;
      background-color: $blue-200;
      overflow: visible;
      transition: flex 0.4s ease-in-out;
      padding: 0rem 1.5rem;

      .card-number-container {
        display: flex;
        justify-content: end;
        padding: 1rem;

        .card-number {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 46px;
          // height: 46px;
          background-color: white;
          color: $turquoise-100;
          font-weight: 500;
          font-size: 22px;
          border-radius: 50%;
          padding: 0.5rem;
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 0rem;
        height: 100%;

        .title-separator-container {
          .card-title {
            font-weight: 300;
            font-size: 24px;
            color: $turquoise-100;
            letter-spacing: 0.5px;
            margin-bottom: 0.8rem;
          }

          .separator {
            height: 1px;
            width: 100%;
            background-color: $gray-500;
            z-index: 10;
          }
        }
        .card-desc {
          display: flex;
          justify-content: end;
          width: 100%;
          float: right;
          // margin-top: 8rem;
          line-height: 24px;
          margin-bottom: 2rem;
          color: black;
          font-size: 20px;
          letter-spacing: 0.5px;
          font-weight: 300;
          opacity: 0;
          max-height: 350px;
          transition: opacity 0.5s ease-in-out;

          p {
            font-weight: 300;
            max-width: 450px;
            padding-bottom: 1rem;
          }
        }

        .card-desc-more-margin {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 480px) {
    border-radius: 16px;
  }
}

@media (max-width: 768px) {
  .our-strategy-container {
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 10px;

    .os-title-svg-container {
      flex-direction: column;

      .os-title-container {
        flex: 1;

        .os-title {
          font-size: 20px;
          font-weight: 300;
        }

        .os-separator {
          width: 100%;
          height: 1px;
          background-color: $gray-500;
          margin-top: 0.6rem;
          margin-bottom: 1.2rem;
        }

        .os-desc {
          max-width: 70%;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.5px;
        }
      }

      .os-svg-container {
        padding: unset;
        width: 100%;
        margin: 1.5rem 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .os-cards-container {
      display: flex;
      height: 544px;
      gap: 1rem;
      justify-content: space-between;

      .os-card-normal {
        flex: 1;
      }

      .os-card-expanded {
        flex: 3;

        .card-title {
          width: 300px;
          p {
            font-weight: 600;
          }
        }
      }

      .os-card {
        width: 291px;
        background-color: $blue-200;
        padding: 0rem 1.5rem;

        .card-number-container {
          display: flex;
          justify-content: end;
          padding: 1rem;

          .card-number {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            background-color: white;
            color: $turquoise-100;
            font-weight: 500;
            font-size: 22px;
            border-radius: 50%;
            padding: 0.5rem;
          }
        }

        .card-title {
          font-weight: 300;
          font-size: 24px;
          color: $turquoise-100;
          letter-spacing: 0.5px;
          margin-bottom: 0.8rem;
        }

        .separator {
          height: 1px;
          width: 100%;
          background-color: $gray-500;
          z-index: 10;
        }
        .card-desc {
          display: flex;
          justify-content: end;
          width: 100%;
          float: right;
          // margin-top: 8rem;
          line-height: 24px;
          color: black;
          font-size: 20px;
          letter-spacing: 0.5px;

          P {
            font-weight: 300;
            width: 450px;
          }
        }

        .card-desc-more-margin {
          // margin-top: 12rem;
        }
      }
    }
  }
}
