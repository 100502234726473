@import "../../../DesignSystem/library";

.homepage-container {
  display: flex;
  justify-content: center;
  height: calc(100vh - 130px - 1rem);
  font-family: $font-primary;
  background: linear-gradient(
    165.13deg,
    #f8f8f8 1.22%,
    #f1f7ff 28.96%,
    #eee7f1 79.91%,
    #f1f9e3 100.7%
  );

  .video-container {
    width: 100%;
    position: relative;
    border-radius: 30px;

    video {
      border-radius: 30px;
      object-fit: cover;
    }

    .video-text-container {
      position: absolute;
      bottom: 3rem;
      left: 2.5rem;
      color: white;
      font-size: 64px;
      width: 678px;

      p {
        font-weight: 300;
        span {
          font-weight: 600;
        }
      }

      .git-btn-container {
        margin-top: 2rem;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: calc(80vh - 130px - 1rem);
    border-radius: 16px;

    .video-container {
      border-radius: 16px;

      video {
        border-radius: 16px;
      }
      .video-text-container {
        border-radius: 16px;
        top: 2rem;
        left: 1.5rem;
        font-size: 32px;
        width: 80%;
        line-height: 40px;

        div {
          font-weight: 300;
        }
      }
      .git-btn-container {
        position: absolute;
        bottom: -1rem;
        left: 1%;
        margin: 0;
      }
    }
  }
}
